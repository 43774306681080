<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="190px">
      <el-form-item label="收款账户类型" prop="region">
        <el-select v-model="form.type" placeholder="请选择收款账户类型" @change="typeChange">
<!--          <el-option label="微信" value="1"></el-option>-->
          <el-option label="支付宝" value="2"></el-option>
          <el-option label="银行卡" value="3"></el-option>
        </el-select>
      </el-form-item>
      <!--   微信   -->
      <el-form-item v-if="form.type == '1'" label="微信收款二维码" prop="cover">
        <el-upload ref="uploadFile" :auto-upload="false" :file-list="fileList"
                   :http-request="handleChange" :limit="1"
                   :on-change="changeFile"
                   accept=".png,.jpg,.jpge"
                   action="string"
                   class="upload-demo"
                   drag>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将文件拖到此处，或<em>点击选取文件</em>
          </div>
          <div slot="tip" class="el-upload__tip">
            只能上传png、jpg、jpge文件，且不超过2MB
          </div>
        </el-upload>
      </el-form-item>

      <!--   支付宝   -->
      <el-form-item v-if="form.type == '2' || form.type == '3'" label="收款账号（或银行卡号）"
                    prop="content">
        <el-input v-model="form.content" placeholder="收款账号（或银行卡号）"></el-input>
      </el-form-item>

      <el-form-item v-if="form.type != ''" label="收款人姓名" prop="name">
        <el-input v-model="form.name" placeholder="请输入内容"></el-input>
      </el-form-item>

      <!--   银行卡   -->
      <el-form-item v-if="form.type == '3'" label="开户行" prop="bankName">
        <el-input v-model="form.bankName" placeholder="请输入开户行"></el-input>
      </el-form-item>
      <!--   账户类型   -->
      <el-form-item v-if="form.type == '3'" label="账户类型" prop="isBank">
        <el-select v-model="form.isBank" placeholder="请选择账户类型">
          <el-option label="对公账户" value="1"></el-option>
<!--          <el-option label="个人账户" value="2"></el-option>-->
        </el-select>
      </el-form-item>
      <slot></slot>

    </el-form>
  </div>
</template>

<script>
import {uploadFileApi} from "@/api/uploadFileApi";
import {addCardApi} from "@/api/cardApi";

export default {
  data() {
    const validateLogo = (rule, value, callback) => {
      if (this.form.type==1&&this.fileList.length==0) {
        callback(new Error('请上传图片'))
      } else {
        callback()
      }
    }
    return {
      form: {
        // 收款账户类型
        type: "",
        // 收款人姓名
        name: "",
        // 收款账号
        content: "",
        // 开户行
        bankName: "",
        // 账户类型
        isBank: "",
        cover: null
      },
      rules: {
        type: [
          {required: true, message: '请选择收款账户类型', trigger: 'change'}
        ],
        name: [
          {required: true, message: '请输入收款人姓名', trigger: 'blur'}
        ],
        bankName: [
          {required: true, message: '请输入开户行', trigger: 'blur'}
        ],
        cover: [
          {required: true, validator: validateLogo, trigger: 'change'}
        ],
        content: [
          {required: true, message: '请输入收款账号（或银行卡号）', trigger: 'blur'}
        ],
        isBank: [
          {required: true, message: '请选择账户类型', trigger: 'change'}
        ]
      },
      fileList: [],
    }
  },
  methods: {
    changeFile(a, b, c) {
      this.fileList = b
    },
    onSubmit() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          if (this.fileList.length!=0) {
            await this.$refs.uploadFile.submit();
          }else{
            this.handleChange()
          }
        } else {
          return false;
        }
      });

    },
    async handleChange(param) {
      if (this.form.type == 1) {
        const data = new FormData();
        if (this.fileList.length == 0) {
          this.$swal.fire({
            icon: "error",
            title: "请上传微信收款码截图",
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
          });
          return;
        }else{
         
          if (param.file.size >= 2 * 1024 * 1024) {
          this.$message({
            message: '文件过大,最大2MB',
            type: "warning",
          });
            return;
          }
        }
      
        data.append('file', param.file);
        data.append('moduleName', "finance/rechargeRecords");
        let {data: res} = await uploadFileApi(data);
        this.fileList=[]
        this.form.content = res.filePath
      }
      let {data: resCz} = await addCardApi(this.form);
      this.$emit('getOrderTwoList')
      this.$swal.fire({
        icon: "success",
        title: "添加成功",
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 2000,
      });
    },
    clearData() {
      let oldType = this.form.type;
      this.form = {
        type: oldType,
        // 收款人姓名
        name: "",
        // 收款账号
        content: "",
        // 开户行
        bankName: "",
        // 账户类型
        isBank: ""
      }
    },
    typeChange(e) {
      this.clearData();
    }
  },

}

</script>
<style scoped>

</style>