<template>
  <el-dialog
      title="创建店铺"
      :visible.sync="dialog"
      top="30px"
      width="80%">
    <el-row :gutter="20" style="margin-top: 10px">
      <el-col :span="5">
        <div class="card card-flush py-4">
          <div class="card-header">
            <div class="card-title">
              <h2 class="required">店铺Logo</h2>
            </div>
          </div>
          <div class="card-body text-center pt-0" @click="openMaterial('logo')">

            <div data-kt-image-input="true" class="image-input image-input-outline blankPng"
                 style="background-image: url(&quot;assets/media/avatars/blank.png&quot;);">
              <div class="image-input-wrapper w-125px h-125px"
                   :style="`background-image:url('${storeInfo.storeLogo}')`"></div>
              <label data-bs-original-title="上传图像" data-bs-toggle="tooltip" data-kt-image-input-action="change"
                     title="" class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow">
                <i class="bi bi-pencil-fill fs-7"></i>
              </label>
              <span data-bs-original-title="取消" data-bs-toggle="tooltip" data-kt-image-input-action="cancel"
                    title="" class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow">
                    <i class="bi bi-x fs-2"></i>
                </span>
            </div>
            <div class="text-muted fs-7"> 设置店铺头图。仅上传 *.png、*.jpg 和 *.jpeg 图片文件。</div>
          </div>
        </div>
        <div class="card card-flush py-4" style="margin-top:20px;">
          <div class="card-body">
            <label
                class="fs-6 form-label fw-bolder text-dark required">
              店铺营业执照
            </label>
            <div style="margin-bottom: 10px" v-if="storeInfo.businessUrl">
              <img :src="storeInfo.businessUrl" width="214" height="116" alt=""/>
              <div
                  style="width: 214px; height: 30px; line-height: 30px; padding: 0 30px; color: rgb(255, 255, 255); display: flex; justify-content: space-around; background: rgb(45, 157, 209);">
                <div @click="openMaterial('business')">
                  <div tabindex="0" class="el-upload el-upload--text">
                    <div style="cursor: pointer; color: rgb(255, 255, 255);font-size: 12px"> 重新上传</div>
                  </div>
                </div>
                <div> |</div>
                <div style="cursor: pointer; color: rgb(255, 255, 255);font-size: 12px"> 预览</div>
              </div>
            </div>
            <div
                v-else
                @click="openMaterial('business')"
                style="width: 214px; height: 30px; line-height: 30px; padding: 0 30px; color: rgb(255, 255, 255); display: flex; justify-content: space-around; background: rgb(45, 157, 209);">
              <div>
                <div tabindex="0" class="el-upload el-upload--text">
                  <div style="cursor: pointer; color: rgb(255, 255, 255);font-size: 12px">上传店铺营业执照</div>
                </div>
              </div>
            </div>
            <label
                class="fs-6 form-label fw-bolder text-dark required">
              店铺资质
            </label>
            <div style="margin-bottom: 5px" v-for="(item,index) in storeInfo.qualificationUrl" :key="index">
              <el-select v-model="item.type" placeholder="请选择" size="mini">
                <el-option
                    v-for="item in qualificationType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              <div style="position: relative">
                <div
                    style="position: absolute;top: 0;left: 0;background: #2D9DD1;color: #fff;padding: 0 10px;border-radius: 0 0 10px 0;font-size: 10px">
                  {{ item.type | qualificationTypeF }}
                </div>
                <img :src="item.img" width="214" height="116" alt=""/>
                <div
                    style="width: 214px; height: 30px; line-height: 30px; padding: 0 30px; color: rgb(255, 255, 255); display: flex; justify-content: space-around; background: rgb(45, 157, 209);">
                  <div @click="openMaterial('qualification',index)">
                    <div tabindex="0" class="el-upload el-upload--text">
                      <div style="cursor: pointer; color: rgb(255, 255, 255);font-size: 12px"> 重新上传</div>
                    </div>
                  </div>
                  <div>|</div>
                  <div style="cursor: pointer; color: rgb(255, 255, 255);font-size: 12px"> 预览</div>
                  <div>|</div>
                  <div style="cursor: pointer; color: rgb(255, 255, 255);font-size: 12px"> 移除</div>
                </div>
              </div>
            </div>
            <div
                style="color: #fff;text-align: center;background: rgb(45, 157, 209);width: 214px;margin-top: 10px;cursor: pointer;height: 30px;line-height: 30px"
                @click="addQualification">
              <i class="el-icon-plus"></i>添加更多资质
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="19">
        <div class="flex-column flex-row-fluid gap-7 gap-lg-10" style="margin-right: 10px;border-radius: 15px">
          <div class="tab-content">
            <div id="kt_ecommerce_add_product_general" role="tab-panel"
                 class="tab-pane fade show active">
              <div class="d-flex flex-column gap-7 gap-lg-10">
                <div class="card card-flush py-4"
                     style="border-radius: 0 0 15px 15px !important; ">
                  <div class="card-header">
                    <div class="card-title">
                      <h2 class="required">店铺名称</h2>
                      <el-tooltip content="请填写实际经营的店铺名称，如：XXX店" effect="dark"
                                  placement="bottom">
                        <svg t="1721716310377" class="icon" viewBox="0 0 1024 1024" version="1.1"
                             xmlns="http://www.w3.org/2000/svg" p-id="1466" width="14" height="14" style="margin-left: 3px;margin-top: 4px">
                          <path d="M464 784.352a1.5 1.5 0 1 0 96 0 1.5 1.5 0 1 0-96 0z" p-id="1467" fill="#515151"></path>
                          <path
                              d="M512 960C264.96 960 64 759.04 64 512S264.96 64 512 64s448 200.96 448 448-200.96 448-448 448z m0-831.712c-211.584 0-383.712 172.128-383.712 383.712 0 211.552 172.128 383.712 383.712 383.712 211.552 0 383.712-172.16 383.712-383.712 0-211.584-172.16-383.712-383.712-383.712z"
                              p-id="1468" fill="#515151"></path>
                          <path
                              d="M512 673.696c-17.664 0-32-14.336-32-32v-54.112c0-52.352 40-92.352 75.328-127.648C581.216 434.016 608 407.264 608 385.92c0-53.344-43.072-96.736-96-96.736-53.824 0-96 41.536-96 94.56 0 17.664-14.336 32-32 32s-32-14.336-32-32c0-87.424 71.776-158.56 160-158.56S672 297.28 672 385.92c0 47.904-36.32 84.192-71.424 119.296C572.736 532.992 544 561.728 544 587.552v54.112c0 17.664-14.336 32.032-32 32.032z"
                              p-id="1469" fill="#515151"></path>
                        </svg>
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="card-body pt-0">
                    <div class="mb-10 fv-row d-flex" style="justify-content: space-between">
                      <input type="text" name="product_name" placeholder="实际经营的店铺名称" v-model="storeInfo.storeName"
                             class="form-control form-control-lg form-control-solid" style="width: 41%">
                      <select data-placeholder="全部" class="form-select form-select-solid" style="width: 19%" v-model="storeInfo.categoryId2" @change="changeCategory(2)">
                        <option value="" label="请选择一级分类" hidden></option>
                        <option :value="item.id" v-for="(item,index) in category2List" :key="index">{{ item.categoryName }}</option>
                      </select>
                      <select data-placeholder="全部" class="form-select form-select-solid" style="width: 19%" v-model="storeInfo.categoryId3" @change="changeCategory(3)">
                        <option value="" label="请选择二级分类" hidden></option>
                        <option :value="item.id" v-for="(item,index) in category3List" :key="index">{{ item.categoryName }}</option>
                      </select>
                      <select data-placeholder="全部" class="form-select form-select-solid" style="width: 19%" v-model="storeInfo.categoryId4" @change="changeCategory(4)">
                        <option value="" label="请选择三级分类" hidden></option>
                        <option :value="item.id" v-for="(item,index) in category4List" :key="index">{{ item.categoryName }}</option>
                      </select>

                    </div>
                    <div class="card-body border-top" style="padding-left: 0;padding-right: 0">
                      <div class="row mb-12">

                        <label class="col-lg-1 col-form-label fw-bold fs-6">
                          <span class="required">店铺电话</span>
                        </label>
                        <div class="col-lg-5 fv-row">
                          <input type="text"
                                 placeholder="请输入店铺电话"
                                 v-model="storeInfo.storeTel"
                                 class="form-control form-control-lg form-control-solid">
                        </div>
                      </div>
                      <div class="row mb-12" v-if="false">
                        <label class="col-lg-1 col-form-label fw-bold fs-6">
                          <span>代收服务</span>
                        </label>
                        <div class="col-lg-5 fv-row">
                          <div class="d-flex align-items-center mt-3">
                            <div class="col-lg-2 form-check form-check-inline form-check-solid me-5">
                              <input name="collection" type="radio" checked="checked" class="form-check-input" value="true" v-model="storeInfo.collection">
                              <span class="fw-bold ps-2 fs-6">
                                开启
                              </span>
                            </div>
                            <div class="col-lg-2 form-check form-check-inline form-check-solid">
                              <input name="collection" type="radio" class="form-check-input" value="false"  v-model="storeInfo.collection">
                              <span class="fw-bold ps-2 fs-6">
                                关闭
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-12" v-if="false">
                        <label class="col-lg-1 col-form-label fw-bold fs-6">
                          <span>营业时间</span>
                        </label>
                        <div class="col-lg-11 fv-row">
                          <div class="d-flex align-items-center mt-3" style="flex-wrap: wrap">
                            <div style="margin-right: 10px;margin-bottom: 10px;display: flex;align-items: center;width: 40%" v-for="(item,index) in openingHours">
                              <el-time-picker
                                  class="store_time_picker"
                                  is-range
                                  format="HH:mm"
                                  arrow-control
                                  style="width: 100%"
                                  range-separator="至"
                                  start-placeholder="开始时间"
                                  end-placeholder="结束时间"
                                  v-model="openingHours[index]"
                                  placeholder="选择时间范围">
                              </el-time-picker>
                              <div style="width: 120px;margin-left: 10px">
                                <el-link icon="el-icon-close" @click="deleteOpeningHours(index)" :underline="false">删除</el-link>
                              </div>
                            </div>
                          </div>
                          <div>
                            <el-link icon="el-icon-plus" @click="addOpeningHours" :underline="false" type="danger">添加</el-link>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-10" v-if="false">

                        <label class="col-lg-1 col-form-label fw-bold fs-6">
                          <span class="required">
                            店铺地址
                          </span>
                        </label>
                        <div class="col-lg-5 fv-row" id="storeAddress">
                          <el-autocomplete
                              popper-class="my-autocomplete"
                              style="width: 100%;color: #4B5675;background: #F9F9F9;border: none"
                              v-model="storeInfo.chooseAddress"
                              :fetch-suggestions="querySearchAsync"
                              @select="handleSelect"
                              placeholder="请输入店铺地址"
                          >
                            <template slot-scope="{ item }">
                              <div class="name">{{ item.title }}</div>
                              <span class="addr" style=" font-size: 12px;
      color: #b4b4b4;">{{ item.address }}</span>
                            </template>
                          </el-autocomplete>
                        </div>
                        <label class="col-lg-1 col-form-label fw-bold fs-6">
                          <span class="required">
                            详细地址
                          </span>
                        </label>
                        <div class="col-lg-5 fv-row" id="storeAddress">
                          <input type="text"
                                 placeholder="请输入店铺详细地址"
                                 v-model="storeInfo.storeAddress"
                                 class="form-control form-control-lg form-control-solid">
                        </div>
                      </div>
                      <div id="containerMaps" style="border-radius: 15px"></div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <sj-button type="secondary" @click="dialog = false">取消</sj-button>
      <sj-button type="success" @click="throttledSubmit" style="margin-left: 20px">保存</sj-button>
    </span>
    <material-component ref="MaterialComponent" @useImg="useImg"></material-component>
  </el-dialog>
</template>
<script>
import {mixins} from "@/views/Store/create/mixins";

export default {
  mixins: [mixins],
}
</script>
<style>
.store_time_picker {
  background: rgb(249, 249, 249);
  border: none;
}

.store_time_picker .el-range-separator {
  width: 30px !important;
  background: rgb(249, 249, 249);
}

.store_time_picker .el-range-input {
  background: rgb(249, 249, 249);
}
</style>
