import service from "@/utils/request"

export function queryNoSelectionComboPageApi(params) {
    return service({
        url: "/selectionCombo/queryNoSelectionComboPage",
        method: "get",
        params
    })
}

export function queryNoSelectionGoodsPageApi(params) {
    return service({
        url: "/selectionGoods/queryNoSelectionGoodsPage",
        method: "get",
        params
    })
}

export function querySelectionComboPageApi(params) {
    return service({
        url: "/selectionCombo/querySelectionComboPage",
        method: "get",
        params
    })
}

export function querySelectionGoodsPageApi(params) {
    return service({
        url: "/selectionGoods/querySelectionGoodsPage",
        method: "get",
        params
    })
}

export function addComboListApi(data) {
    return service({
        url: "/selectionCombo/addComboList",
        method: "post",
        data
    })
}
export function removeComboListApi(data) {
    return service({
        url: "/selectionCombo/removeComboList",
        method: "post",
        data
    })
}

