<template>
  <el-dialog
      title="编辑状态"
      :visible.sync="dialogVisible"
      width="30%"
      top="10px"
      :before-close="handleClose">
    <div class="row">
      <div class="row">
        <label class="col-lg-2 col-form-label fw-bold fs-6" style="display: flex;align-items: center">
          <span>代收服务</span>
        </label>
        <div class="col-lg-5 fv-row">
          <div class="d-flex align-items-center mt-3">
            <div class="col-lg-6 form-check form-check-inline form-check-solid me-5">
              <input name="collection" type="radio" checked="checked" class="form-check-input" value="true"
                     v-model="storeInfo.collection">
              <span class="fw-bold ps-2 fs-6">
                  开启
              </span>
            </div>
            <div class="col-lg-6 form-check form-check-inline form-check-solid">
              <input name="collection" type="radio" class="form-check-input" value="false"
                     v-model="storeInfo.collection">
              <span class="fw-bold ps-2 fs-6">
                    关闭
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <label class="col-lg-2 col-form-label fw-bold fs-6" style="display: flex;align-items: center">
          <span>营业状态</span>
        </label>
        <div class="col-lg-5 fv-row">
          <div class="d-flex align-items-center mt-3">
              <select class="form-select form-select-solid" data-placeholder="全部" style="width: 100%" v-model="storeInfo.status">
                <option :value="1">营业中</option>
                <option :value="2">已关门</option>
                <option :value="3">筹建中</option>
                <option :value="4">暂停营业</option>
                <option :value="5">关停</option>
              </select>
            </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <sj-button type="secondary" @click="dialogVisible = false">取消</sj-button>
      <sj-button type="success" @click="saveStoreStatus" style="margin-left: 20px">保存</sj-button>
    </span>
  </el-dialog>
</template>
<script>
import {updateStoreStatusApi} from "@/api/lxh/store";

export default {
  title: 'editStatus',
  data() {
    return {
      dialogVisible: false,
      storeInfo: {
        collection: true,
        status:1,
      }
    }
  },
  created() {
  },
  methods: {
    async saveStoreStatus(){
      let {data}=await updateStoreStatusApi(this.storeInfo)
      this.dialogVisible = false
      this.$emit('getList')
      this.$message({
        message: '保存成功！',
        type: 'success'
      });
    },
    init(item) {
      this.storeInfo = JSON.parse(JSON.stringify(item))
      this.dialogVisible = true
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {
          });
    }
  }
}
</script>
