import {queryTransactionHistoryPageApi} from "@/api/lxh/finance/offline/transactionHistoryApi";

export const mixins = {
    components: {},
    data() {
        return {
            rows: [],
            total: 0,
            params: {
                current: 1,
                size: 10
            }
        }
    },
    methods: {
        async getPage() {
            let {data} = await queryTransactionHistoryPageApi(this.params)
            this.rows = data.rows
            this.total = data.total
        }
    },
    created() {
        this.getPage()
    }
}
