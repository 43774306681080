// user.js
import router from '@/router';
import {
    getLoginRoleApi,
    getMyPermissionApi,
    loginByCodeApi,
    userLoginApi,
    userLoginEnterpriseApi,
    userLogoutApi
} from "@/api/userApi"
import { removeToken, setToken } from '@/utils/auth';
import Swal from 'sweetalert2';
const state = {
    permissionList: [],
    userInfo: {

    },
    role:[]
};

const mutations = {
    //设置权限状态列表
    setPermissionList(state, permissionList) {
        state.permissionList = permissionList

    },
    //设置用户信息
    setUserInfo(state, userInfo) {
        state.userInfo = userInfo
    },
    //设置角色
    setRole(state, role) {
        state.role = role
    },
};

const actions = {
    // 用户退出登录
    async loginOut({ commit }, parame) {
        return new Promise(async (resolve, reject) => {
            if (parame) {
                Swal.fire({
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 2000,
                    icon: 'success',
                    title: '退出成功'
                })
                let { data } = await userLogoutApi();
            }
            localStorage.removeItem('permissions');
            removeToken()
            router.push('/login');
            
            resolve();
        });
    },
    // 用户登录
    async userLogin({ commit ,dispatch}, parame) {
        localStorage.removeItem("permissions")
        let { data } = await userLoginEnterpriseApi(parame);
        setToken(data)
        await dispatch("getUserPermission")
        const redirectPath = localStorage.getItem('redirectPath');
        Swal.fire({
            toast: true,
            position: 'center',
            showConfirmButton: false,
            timer: 2000,
            icon: 'success',
            title: '登录成功'
        })
        if (redirectPath) {
            router.push(redirectPath);
        } else {
            router.push('/');
        }
        window.location.reload();
    },
    async userTelCodeLogin({ commit ,dispatch}, parame) {
        localStorage.removeItem("permissions")
        let { data } = await loginByCodeApi(parame);
        setToken(data)
        await dispatch("getUserPermission")
        const redirectPath = localStorage.getItem('redirectPath');
        Swal.fire({
            toast: true,
            position: 'center',
            showConfirmButton: false,
            timer: 2000,
            icon: 'success',
            title: '登录成功'
        })
        if (redirectPath) {
            router.push(redirectPath);
        } else {
            router.push('/');
        }
        window.location.reload();
    },
    // 获取用户信息
    async getUserInfo({ commit ,dispatch}) {
        commit("setUserInfo", 1)
        await dispatch("getUserPermission")
    },
    // 获取并执行设置权限状态
    async getUserPermission({ commit }) {
      try {
          let { data } = await getMyPermissionApi();
          let arr = []
          data.forEach(element => {
              arr.push(element.node)
          });
          localStorage.setItem('permissions', JSON.stringify(arr));
          commit("setPermissionList", arr)

          let { data:res } = await getLoginRoleApi();
          localStorage.setItem('role', JSON.stringify(res));
          commit("setRole", res)
      }catch (e){
          router.replace('/login');
      }


    }
};

const getters = {

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
