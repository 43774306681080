import service from "@/utils/request"


/**
 * 获取订单列表
 */
export function queryTransactionHistoryPageApi(params) {
    return service({
        url: "/transaction/history/getPage",
        method: "get",
        params
    })
}

/**
 * 财务总览 详情
 * @param params
 * @returns {*}
 */
export function financialOverviewInfoApi(params) {
    return service({
        url: "/transaction/history/financialOverviewInfo",
        method: "get",
        params
    })
}

/**
 * 财务总览 数值
 * @param params
 * @returns {*}
 */
export function financialOverviewApi(params) {
    return service({
        url: "/transaction/history/financialOverview",
        method: "get",
        params
    })
}

/**
 * 获取提现总金额/冻结金额/可提现金额
 */
export function getWithdrawalInfoApi() {
    return service({
        url: "/transaction/history/getWithdrawalInfo",
        method: "get"
    })
}
