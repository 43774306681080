// user.js
const state = {
    webUrl: "c.xy-sy.com",
    obj: {
        webUrl: "",
        webIcp: "",
        email: "",
        phone: "",
        msg: "",
        webTitle: ""
    },
    webObj: [
        {
            webTitle: "向阳岁月企业端",
            icon: "/lxh-favicon.ico",
            webUrl: "https://c.xy-sy.com",
            logoUrl: "./2.png",
            headerLogoUrl: "./lxh-logo-c.png",
            tel: "400-840-8088",
            webIcp: "浙ICP备2024107056号",
            email: "server@enower.com",
            phone: "400-840-8088",
            msg: "2024-2032©向阳岁月",
            keFu: "./images/zhilidao_kefu.png",
            tplId: "265789",
            accountName: "杭州联享惠技术有限公司",
            openingBank: "",
            account: ""
        }
    ]
};

const mutations = {
    SET_WEBICP(state, payload) {
        state.obj = payload;
        document.title = payload.webTitle;
    },
    SET_ICON(state, payload) {
        const link = document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'icon';
        link.href = payload.icon;
        const head = document.head || document.getElementsByTagName('head')[0];
        const oldFavicons = document.querySelectorAll('link[rel="icon"]');
        oldFavicons.forEach(oldFavicon => head.removeChild(oldFavicon));
        head.appendChild(link);
    }
};

const actions = {
    /**
     * 设置webIcp
     */
    setWebIcp({commit}, amount) {
        commit('SET_WEBICP', amount)
        commit('SET_ICON', amount)
    },
    setIcon({commit}, amount) {
        commit('SET_ICON', amount)
    }
};

const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
