<template>
  <div style="min-height: 80vh">
    <div class="card bg-light shadow-sm" style="background: #fff;margin-top: 10px;border-radius: 15px">
      <div class="card-header collapsible cursor-pointer rotate"
           style="background: #fff;border-radius: 15px;min-height:25px">
        <div style="font-weight: bold;height: 25px;display: flex;align-items: center" class="card-title">
          <div>
            {{ this.$route.matched[1].meta.title }}
          </div>
        </div>

        <div class="card-toolbar">
          <el-button type="primary" size="small" round @click="addInvoice">添加发票抬头</el-button>
        </div>
      </div>
    </div>
    <div v-if="invoiceList.length" class="card mb-5 mb-xl-8" style="margin-left: 3px; width: 100%;margin-top: 10px">
      <div class="card-body py-3">
        <div class="table-responsive">
          <table class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer">
            <thead>
            <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
              <th  class="text-center">
                发票抬头
              </th>
              <th  class="text-center">
                发票税号
              </th>
              <th  class="text-center">
                注册地址
              </th>
              <th class="text-center">
                注册电话
              </th>
              <th class="text-center">
                开户银行
              </th>
              <th class="text-center">
                银行账号
              </th>
              <th class="text-center">
                收票人邮箱
              </th>
              <th class="text-center">
                是否专票
              </th>
              <th class="text-center">
                操作
              </th>
            </tr>
            </thead>
            <tbody class="fw-semibold text-gray-600">
            <tr v-for="(item,index) in invoiceList" :key="index">
              <td class="text-center">
                {{ item.fpName }}
              </td>
              <td class="text-center">{{ item.fpNo }}</td>
              <td class="text-center">{{ item.fpAdd }}</td>
              <td class="text-center">{{ item.fpTel }}</td>
              <td class="text-center">{{ item.fpYh }}</td>
              <td class="text-center">{{ item.fpYhzh }}</td>
              <td class="text-center">{{ item.sjEmail }}</td>
              <td class="text-center">
                <span v-if="item.special" class="">是</span>
                <span v-else class="">否</span>
              </td>
              <td class="text-center">
                <el-link type="primary" @click="clickEdit(item.id)">编辑</el-link>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-else class="card" style="margin-top: 10px">
      <div class="card-body">
        <div class="card-px text-center pt-15 pb-15">
          <h2 class="fs-2x fw-bold mb-0">
            为你的账号来添加一个发票抬头
          </h2>
          <p class="text-gray-400 fs-4 fw-semibold py-7">
            点击下方按钮
            <br />
            来添加你的第一个发票抬头
          </p>
          <a
              class="btn btn-primary er fs-6 px-8 py-4"
              href="javascript:void(0)"
          >添加发票抬头</a
          >
        </div>
        <div class="text-center pb-15 px-5">
          <img
              alt=""
              class="mw-100 h-200px h-sm-325px"
              src="../../../../assets/InvoiceNull.png"
          />
        </div>
      </div>
    </div>
    <InvoiceCreate ref="InvoiceCreate" @getInvoiceList="getInvoiceList"></InvoiceCreate>
    <InvoiceEdit ref="InvoiceEdit" @getInvoiceList="getInvoiceList"></InvoiceEdit>
  </div>
</template>
<script>
import {mixins} from "@/views/Finance/components/bill/mixins";

export default {
  mixins: [mixins],
}
</script>
