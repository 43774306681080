import { render, staticRenderFns } from "./CardList.vue?vue&type=template&id=75ac8813&scoped=true&xmlns=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml"
import script from "./CardList.vue?vue&type=script&lang=js"
export * from "./CardList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75ac8813",
  null
  
)

export default component.exports