export const qualificationType=[
    {
        "label": "医疗机构执业许可证",
        "value": 2
    },
    {
        "label": "纹绣师资格证",
        "value": 3
    },
    {
        "label": "卫生许可证",
        "value": 5
    },
    {
        "label": "医疗器械经营许可证",
        "value": 6
    },
    {
        "label": "卫生机构（组织）代码证",
        "value": 7
    },
    {
        "label": "医疗广告审查证明",
        "value": 8
    },
    {
        "label": "汽车维修经营许可证",
        "value": 9
    },
    {
        "label": "道路运输经营许可证",
        "value": 10
    },
    {
        "label": "成品油零售经营批准证书",
        "value": 11
    },
    {
        "label": "危险化学品经营许可证书",
        "value": 12
    },
    {
        "label": "计量检定合格证",
        "value": 13
    },
    {
        "label": "动物诊疗许可证",
        "value": 14
    },
    {
        "label": "公安局备案证明材料",
        "value": 15
    },
    {
        "label": "食品流通许可证",
        "value": 16
    },
    {
        "label": "食品销售许可证",
        "value": 17
    },
    {
        "label": "办学许可证",
        "value": 18
    },
    {
        "label": "自费出国留学中介服务机构资格认定书",
        "value": 19
    },
    {
        "label": "民办学校广告备案证明材料",
        "value": 20
    },
    {
        "label": "化妆品相关批文",
        "value": 21
    },
    {
        "label": "“营业性射击场”相关批文",
        "value": 22
    },
    {
        "label": "守法经营承诺书",
        "value": 23
    },
    {
        "label": "其他资质",
        "value": 24
    },
    {
        "label": "进口产品证明",
        "value": 25
    },
    {
        "label": "品牌授权书或相关合作协议",
        "value": 26
    },
    {
        "label": "会所类KTV资质认证",
        "value": 28
    },
    {
        "label": "商场承诺书",
        "value": 29
    },
    {
        "label": "医疗器械注册证",
        "value": 30
    },
    {
        "label": "药品注册证",
        "value": 31
    },
    {
        "label": "高危险性体育项目经营许可证",
        "value": 32
    },
    {
        "label": "母婴保健技术服务许可证",
        "value": 33
    },
    {
        "label": "养老机构设立许可证",
        "value": 34
    },
    {
        "label": "食品经营许可证",
        "value": 35
    }
]
