<template>
  <div style="min-height: 80vh">
    <div class="flex-column flex-row-fluid gap-7 gap-lg-10" style="border-radius: 15px;margin-top: 10px">
      <div class="tab-content">
        <div class="tab-pane fade show active">
          <div class="d-flex flex-column gap-7 gap-lg-10">
            <div class="card" style="border-radius:15px">
              <div class="card-header" style="display: flex;align-items: center">
                <div style="display:flex;align-items: center;padding: 20px 0;">
                  <div>
                    <div style="color: #C0C0BF;">总金额 (元)
                    </div>
                    <div style="font-weight: bold;color: #FF7F5F;font-size: 24px;margin: 4px 0">
                      &yen;{{ info.totalAmount |formats }}
                    </div>
                    <div style="color: #C0C0BF;font-size: 11px">
                      &nbsp;
                    </div>
                  </div>
                  <div style="margin-left: 20px;padding-right: 10px">
                    <div>
                      &nbsp;
                    </div>
                    <div style="font-weight: bold;font-size: 18px;">=</div>
                    <div>
                      &nbsp;
                    </div>
                  </div>
                  <div style="margin-left: 10px">
                    <div style="color: #C0C0BF;">
                      冻结金额(元)
                      <el-tooltip class="item" content="未满足D+1的订单金额统计" effect="dark" placement="top-end"
                                  style="cursor: pointer">
                        <i class="el-icon-question"></i>
                      </el-tooltip>
                    </div>
                    <div style="font-weight: bold;font-size: 24px;margin: 4px 0">&yen;{{ info.freezeAmount |formats }}</div>
                    <div style="color: #C0C0BF;font-size: 11px">
                      <el-link style="font-size: 11px" type="primary">查看冻结金额明细
                        <i class="el-icon-arrow-right"></i></el-link>
                      <!--&nbsp;-->
                    </div>
                  </div>
                  <div style="margin-left: 20px;margin-right: 10px">
                    <div>
                      &nbsp;
                    </div>
                    <div style="font-size: 16px;font-weight: bold;">
                      +
                    </div>
                    <div>
                      &nbsp;
                    </div>
                  </div>
                  <div style="margin-left: 14px">
                    <div style="color: #C0C0BF;">
                      可提现金额(元)
                    </div>
                    <div style="font-size: 24px;margin: 4px 0">&yen;{{ info.withdrawalAmount |formats }}</div>
                    <div style="color: #C0C0BF;font-size: 11px">
                      &nbsp;
                    </div>
                  </div>
                  <div style="width: 20px;"></div>
                  <el-button round size="mini" type="primary" @click="openWithdrawal(info.withdrawalAmount)">提现
                  </el-button>
                </div>
              </div>
              <div class="card-body" style="padding-top: 4px">
                <div style="margin-top: 0">
                  <div class="table-responsive" style="min-height: 56vh">
                    <table id="kt_ecommerce_products_table"
                           class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer">
                      <thead>
                      <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                        <th>提现金额
                        </th>
                        <th>
                          提现时间
                        </th>
                        <th>状态
                        </th>
                        <th>提现方式
                        </th>
                        <th>收款账号
                        </th>
                        <th>收款人姓名
                        </th>
                        <th>开户行
                        </th>
                        <th>账号类型
                        </th>
                      </tr>
                      </thead>
                      <tbody class="fw-semibold text-gray-600">
                      <tr v-for="(item,index) in listData" :key="index">
                        <td>
                          {{ item.price |format }}
                        </td>
                        <td>
                          {{ item.createTime }}
                        </td>
                        <td>
                          <el-tag :type="item.status | invoiceStatusTagType" size="mini" :effect="item.status | tagEffect">
                            {{ item.statusStr }}
                          </el-tag>
                        </td>
                        <td>
                          {{ item.typeStr }}
                        </td>
                        <td>
                          <img v-if="item.type === 1" :src="item.content" width="50px"/>
                          <div v-else>
                            {{ item.content }}
                          </div>
                        </td>
                        <td>
                          {{ item.name }}
                        </td>
                        <td>
                          {{ item.bankName }}
                        </td>
                        <td>
                          {{ item.isBankStr }}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                    <div style="display: flex;justify-content: right">
                      <paginations :limit.sync="params.size" :page.sync="params.current" :total="total"
                                   @pagination="getPage"></paginations>
                    </div>
                </div>
              </div>

            </div>
          </div>

        </div>

      </div>
    </div>
    <withdrawInfo ref="withdrawInfo" @listAndHead="listAndHead"></withdrawInfo>
  </div>
</template>
<script>
import {mixins} from "@/views/Finance/components/withdraw/mixins";

export default {
  mixins: [mixins],
}
</script>
