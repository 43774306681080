import service from "@/utils/request"



export function getAfsListApi(params){
   return service({
       url:"/afterSale/getOrderAfsPage",
       method:"get",
       params
   })
}

export function getOrderAfsDetailApi(params){
    return service({
        url:"/afterSale/getOrderAfsDetail",
        method:"get",
        params
    })
}

export  function submitAfsUpdateStatusApi(data){
    return service({
        url:"/afterSale/submitAfsUpdateStatus",
        method:"post",
        data
    })
}