import service from "@/utils/request"

export function getOrderInvoiceOneApi(data) {
    return service({
        url: '/order/sj/fp/one',
        method: 'get',
        params:data
    })
}

export function uploadInvoiceImgApi(data) {
    return service({
        url: "/order/sj/fp/uploadInvoiceImg",
        method: "post",
        data
    })
}