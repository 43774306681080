<template>
  <div id="kt_post" class="post flex-column-fluid">
    <TitleCard title="数字直营店管理" class="mt-5 mb-5" style="margin: 0 auto; max-width: 97%; border-radius: 15px">
      <template v-slot:subTitle class="position-relative">
        <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
          <li class="breadcrumb-item text-muted" rel="stylesheet"
              href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"
              style="font-family: 'Roboto', sans-serif">
            Digital Direct Store Management
          </li>
        </ul>
      </template>
      <div style="margin-right: 35px">
        <el-button type="primary" size="mini" @click="editEdit">编辑</el-button>
      </div>
    </TitleCard>
    <div id="kt_content_container" class="container"
         style="max-width: 98%; flex-direction: row !important; display: flex">
      <div id="kt_app_main" class="app-main flex-column flex-row-fluid">
        <div class="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" class="app-content" style="padding-top: 0px">
            <el-empty description="暂无店铺，快去新建一个" v-if="!storeInfo">
              <el-button type="primary" @click="addStore">新增店铺</el-button>
            </el-empty>
            <div v-else style="min-height: 80vh">
              <el-row :gutter="20" style="margin-top: 10px">
                <el-col :span="5">
                  <div class="card card-flush py-4">
                    <div class="card-header">
                      <div class="card-title">
                        <h2 class="required">店铺Logo</h2>
                      </div>
                    </div>
                    <div class="card-body text-center pt-0">

                      <div data-kt-image-input="true" class="image-input image-input-outline blankPng"
                           style="background-image: url(&quot;assets/media/avatars/blank.png&quot;);">
                        <div class="image-input-wrapper w-125px h-125px"
                             :style="`background-image:url('${storeInfo.storeLogo}')`"></div>

                        <span data-bs-original-title="取消" data-bs-toggle="tooltip" data-kt-image-input-action="cancel"
                              title="" class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow">
                    <i class="bi bi-x fs-2"></i>
                </span>
                      </div>
                      <div class="text-muted fs-7"> 店铺头图。仅上传 *.png、*.jpg 和 *.jpeg 图片文件。</div>
                    </div>
                  </div>
                  <div class="card card-flush py-4" style="margin-top:20px;">
                    <div class="card-body">
                      <label
                          class="fs-6 form-label fw-bolder text-dark required">
                        店铺营业执照
                      </label>
                      <div style="margin-bottom: 10px" v-if="storeInfo.businessUrl">
                        <img :src="storeInfo.businessUrl" width="214" height="116" alt=""/>
                        <div
                            style="width: 214px; height: 30px; line-height: 30px; padding: 0 30px; color: rgb(255, 255, 255); display: flex; justify-content: space-around; background: rgb(45, 157, 209);">
                          <div style="cursor: pointer; color: rgb(255, 255, 255);font-size: 12px"> 预览</div>
                        </div>
                      </div>
                      <div
                          v-else
                          style="width: 214px; height: 30px; line-height: 30px; padding: 0 30px; color: rgb(255, 255, 255); display: flex; justify-content: space-around; background: rgb(45, 157, 209);">
                        <div>
                          <div tabindex="0" class="el-upload el-upload--text">
                            <div style="cursor: pointer; color: rgb(255, 255, 255);font-size: 12px">上传店铺营业执照</div>
                          </div>
                        </div>
                      </div>
                      <label
                          class="fs-6 form-label fw-bolder text-dark required">
                        店铺资质
                      </label>
                      <div style="margin-bottom: 5px" v-for="(item,index) in storeInfo.qualificationUrl" :key="index">
                        <el-select disabled v-model="item.type" placeholder="请选择" size="mini">
                          <el-option
                              v-for="item in qualificationType"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                          </el-option>
                        </el-select>
                        <div style="position: relative">
                          <div
                              style="position: absolute;top: 0;left: 0;background: #2D9DD1;color: #fff;padding: 0 10px;border-radius: 0 0 10px 0;font-size: 10px">
                            {{ item.type | qualificationTypeF }}
                          </div>
                          <img :src="item.img" width="214" height="116" alt=""/>
                          <div
                              style="width: 214px; height: 30px; line-height: 30px; padding: 0 30px; color: rgb(255, 255, 255); display: flex; justify-content: space-around; background: rgb(45, 157, 209);">
                            <div style="cursor: pointer; color: rgb(255, 255, 255);font-size: 12px"> 预览</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="19">
                  <div class="flex-column flex-row-fluid gap-7 gap-lg-10" style="margin-right: 10px;border-radius: 15px">
                    <div class="tab-content">
                      <div id="kt_ecommerce_add_product_general" role="tab-panel"
                           class="tab-pane fade show active">
                        <div class="d-flex flex-column gap-7 gap-lg-10">
                          <div class="card card-flush py-4"
                               style="border-radius: 0 0 15px 15px !important; ">
                            <div class="card-header">
                              <div class="card-title">
                                <h2 class="required">店铺名称</h2>
                              </div>
                            </div>
                            <div class="card-body pt-0">
                              <div class="mb-10 fv-row d-flex" style="justify-content: space-between">
                                <input disabled type="text" name="product_name" placeholder="店铺名称" v-model="storeInfo.storeName"
                                       class="form-control form-control-lg form-control-solid" style="width: 41%">
                                <select disabled data-placeholder="全部" class="form-select form-select-solid" style="width: 19%" v-model="storeInfo.categoryId2" @change="changeCategory(2)">
                                  <option :value="item.id" v-for="(item,index) in category2List" :key="index">{{ item.categoryName }}</option>
                                </select>
                                <select disabled data-placeholder="全部" class="form-select form-select-solid" style="width: 19%" v-model="storeInfo.categoryId3" @change="changeCategory(3)">
                                  <option :value="item.id" v-for="(item,index) in category3List" :key="index">{{ item.categoryName }}</option>
                                </select>
                                <select disabled data-placeholder="全部" class="form-select form-select-solid" style="width: 19%" v-model="storeInfo.categoryId4" @change="changeCategory(4)">
                                  <option :value="item.id" v-for="(item,index) in category4List" :key="index">{{ item.categoryName }}</option>
                                </select>

                              </div>
                              <div class="card-body border-top" style="padding-left: 0;padding-right: 0">
                                <div class="row mb-12">
                                  <label class="col-lg-1 col-form-label fw-bold fs-6">
                                    <span class="required">店铺电话</span>
                                  </label>
                                  <div class="col-lg-5 fv-row">
                                    <input type="text" disabled
                                           placeholder="请输入店铺电话"
                                           v-model="storeInfo.storeTel"
                                           class="form-control form-control-lg form-control-solid">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>

          </div>
        </div>
      </div>
    </div>

    <store-create ref="storeCreate" @getList="getList"></store-create>
        <store-info ref="storeInfo" @getList="getList"></store-info>
    <!--    <edit-status ref="storeEditStatus" @getList="getList"></edit-status>-->
        <edit-time ref="storeEditTime" @getList="getList"></edit-time>
  </div>
</template>
<script>

import {mixins} from "@/views/Store/list/mixins";
import storeCreate from "@/views/Store/create/index.vue";
import storeInfo from "@/views/Store/info/index.vue";
import EditTime from "@/views/Store/edit-time/index.vue";

export default {
  computed: {

  },
  components: {storeCreate,EditTime, storeInfo},

  mixins: [mixins],
  name: "StoreList"
}
</script>
