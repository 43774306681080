import {getWithdrawalInfoApi} from "@/api/lxh/finance/offline/transactionHistoryApi";
import {cashOutPageApi} from "@/api/lxh/finance/offline/cashout";

export const mixins = {
    components: {
        withdrawInfo: () => import('./components/withdrawInfo/index.vue'),
    },
    data() {
        return {
            info: {},
            params: {
                current: 1,
                size: 10,
                isOffline:true
            },
            listData: [],
            total: 0
        }
    },
    filters: {
        invoiceStatusTagType(status) {
            let obj = {
                0: "",
                1: "success",
                2: "danger",
            }
            return obj[status] || "info"
        },
        tagEffect(status){
            let obj={
                0:'light',
                "1":'dark',
                "2":'dark',
                "3":'dark',
                "4":'dark'
            }
            return obj[status];
        },

    },
    methods: {
        openWithdrawal(withdrawalAmount) {
            if (withdrawalAmount === 0) {
                this.$message.warning('没有可提现的金额')
                return
            }
            this.$refs.withdrawInfo.init(withdrawalAmount)
        },
        async getWithdrawalInfo() {
            let {data} = await getWithdrawalInfoApi();
            this.info = data;
        },
        async getPage() {
            let {data} = await cashOutPageApi(this.params)
            this.listData = data.rows
            this.total = total
        },
        listAndHead() {
            this.getWithdrawalInfo()
            this.getPage()

        }
    },
    created() {
        this.listAndHead()
    }
}
