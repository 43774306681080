<template>
  <div class="card border-0 h-md-100" data-bs-theme="light">
    <!--begin::Body-->
    <img src="https://xysy-saas.oss-cn-hangzhou.aliyuncs.com/lianXiangHui/dashboard/banner2.png" width="100%"  @click="toolsClick">
  </div>
</template>
<script>

import {mapState} from "vuex";

export default {
  name: "Jumbotron",
  data() {
    return {
      goodsUrl: ''
    }
  },
  methods: {
    toolsClick(){
      var anchor = document.getElementById('help');
      anchor.scrollIntoView();
    },
    assistant() {
      if (this.goodsUrl == "") {
        this.$swal.fire({
          icon: "error",
          title: "请输入京东商品链接!",
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 2000,
        });
        return;
      }
      let url = encodeURIComponent(this.goodsUrl);
      this.$router.push({
        name: "AddOrUpdateVisible",
        params: {id: url},
      });
    },
    no() {
      this.$router.push({path: '/goods', query: {openExportTool: true}});
    }
  },
  computed: {
    ...mapState("web", ["obj"]),
  },
}
</script>