import service from "@/utils/request"

export function queryCustomCategoryPageApi(params) {
  return service({
    url: "/customCategory/queryCustomCategoryPage",
    method: "get",
    params
  })
}

export function addCustomCategoryApi(data) {
  return service({
    url: "/customCategory/addCustomCategory",
    method: "post",
    data
  })

}

export function updateCustomCategoryApi(data) {
  return service({
    url: "/customCategory/updateCustomCategory",
    method: "post",
    data
  })
}

export function deleteCustomCategoryApi(param){
  return service({
    url: "/customCategory/deleteCustomCategory",
    method: "get",
    params:param
  })
}