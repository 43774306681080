import InvoiceCreate from "@/views/Finance/components/bill/components/InvoiceCreate.vue"
import InvoiceEdit from "@/views/Finance/components/bill/components/InvoiceEdit.vue"
import {getInvoiceListApi} from "@/api/invoiceApi";

export const mixins = {
    components: {
        InvoiceEdit,InvoiceCreate
    },
    data() {
        return {
            invoiceList: [],
            totalCount: 0,
            loading: false,
            params: {
                iDisplayStart: 1,
                iDisplayLength: 10,
                sEcho: "",
                fpName: "",
                fpNo: "",
            },
        }
    },
    methods: {
        addInvoice() {
            this.$refs.InvoiceCreate.init()
        },
        editInvoice(id){
            this.$refs.InvoiceEdit.init(id)
        },
        async getInvoiceList() {
            this.loading = true;
            let {data} = await getInvoiceListApi(this.params);
            this.invoiceList = data.data;
            this.totalCount = data.total;
            this.loading = false;
        },
        clickEdit(data) {
            this.editInvoice(data)
        },
    },
    created() {
        this.getInvoiceList()
    }
}
