import service from "@/utils/request"


export function getJdRegionListApi(params) {
    return service({
        url: '/jdRegion/getJdRegionList',
        method: 'get',
        params
    })
}
