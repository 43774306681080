import service from "@/utils/request"
/**
 * 获取门店列表
 */
export function getStoreListApi(params) {
  return service({
    url: "/store/getStoreList",
    method: "get",
    params
  })
}

/**
 * 门店图片上传
 */
export function uploadStoreImgApi(data) {
  return service({
    url: "/store/file/uploadFile",
    method: "post",
    data
  })
}

/**
 * 新增门店
 */
export function addStoreApi(data) {
  return service({
    url: "/store/addStore",
    method: "post",
    data
  })
}

export function addOnLineStoreApi(data) {
  return service({
    url: "/store/addOnLineStore",
    method: "post",
    data
  })
}

/**
 * 编辑门店
 */
export function updateStoreApi(data) {
  return service({
    url: "/store/updateStore",
    method: "post",
    data
  })
}

export function updateOnLineStoreApi(data) {
  return service({
    url: "/store/updateStore",
    method: "post",
    data
  })
}


/**
 * 编辑门店标签
 */
export function updateStoreLabelApi(data) {
  return service({
    url: "/storeStoreTag/storeUpdateTag",
    method: "post",
    data
  })
}

/**
 * 查门店全部的标签（新建门店）
 */
export function getStoreLabelListApi(params) {
  return service({
    url: "/storeTag/getAllStoreTagList",
    method: "get",
    params
  })
}

/**
 * 查全部的标签，并且返回是否已经选中
 */
export function getStoreLabelListByStoreIdApi(params) {
  return service({
    url: "/storeStoreTag/getAllStoreTagAndChooseList",
    method: "get",
    params
  })
}

/**
 * 获取门店基本信息
 */
export function getStoreInfoApi(params) {
  return service({
    url: "/store/getBaseInfoById",
    method: "get",
    params
  })
}

/**
 * 获取门店头图
 */
export function getStoreHeadImgApi(params) {
  return service({
    url: "/store/getHeadImg",
    method: "get",
    params
  })
}

/**
 * 上传头图
 */
export function uploadStoreHeadImgApi(data) {
  return service({
    url: "/store/uploadHeadImg",
    method: "post",
    data
  })
}

/**
 * 移除头图
 */
export function removeStoreHeadImgApi(params) {
  return service({
    url: "/store/deleteHeadImgById",
    method: "get",
    params
  })
}

/**
 * 保存头图位置
 */
export function saveStoreHeadImgPositionApi(data) {
  return service({
    url: "/store/updateHeadImgSort",
    method: "post",
    data
  })
}

/**
 * 上传门店视频
 */
export function uploadStoreVideoApi(data) {
  return service({
    url: "/store/uploadStoreVideo",
    method: "post",
    data
  })
}

/**
 * 获取店铺分类
 */
export function getStoreCategoryListApi(params) {
  return service({
    url: "/store/offLineCategory/getOffLineCategoryList",
    method: "get",
    params
  })
}

export function getStoreOnLineCategoryListApi(params) {
  return service({
    url: "/store/onLineCategory/getOnLineCategoryList",
    method: "get",
    params
  })
}
//给选品库用的
export function getSelectionCategoryListApi(params) {
  return service({
    url: "/store/offLineCategory/getSelectionCategoryList",
    method: "get",
    params
  })
}

export function getSelectionJdCategoryListApi(params) {
  return service({
    url: "/category/querySelectionCategory",
    method: "get",
    params
  })
}

/**
 * 新增资质
 */
export function addStoreQualificationApi(data) {
  return service({
    url: "/storeBusiness/addStoreBusiness",
    method: "post",
    data
  })
}

/**
 * 编辑资质
 */
export function updateStoreQualificationApi(data) {
  return service({
    url: "/storeBusiness/updateStoreBusiness",
    method: "post",
    data
  })
}

/**
 * 获取资质
 */
export function getStoreQualificationApi(params) {
  return service({
    url: "/storeBusiness/getStoreBusiness",
    method: "get",
    params
  })
}

/**
 * 修改门店状态
 */
export function updateStoreStatusApi(data) {
  return service({
    url: "/store/updateStoreStatus",
    method: "post",
    data
  })
}

/**
 * 更新营业时间
 */
export function updateStoreOpeningHoursApi(data) {
  return service({
    url: "/store/updateStoreBusinessTime",
    method: "post",
    data
  })
}
