import {getStoreListApi, getStoreOnLineCategoryListApi} from "@/api/lxh/store";
import StoreInfo from "@/views/Store/info/index.vue";
import EditStatus from "@/views/Store/edit-status/index.vue";
import EditTime from "@/views/Store/edit-time/index.vue";
import {qualificationType} from "@/utils/qualificationType";

export const mixins = {
    components: {StoreInfo,EditStatus,EditTime},
    data() {
        return {
            params:{
                current:1,
                size:1
            },
            storeInfo: {
                id:"",
                storeName: "",
                categoryId2:"",
                categoryId3:"",
                categoryId4:"",
                branchName: "",
                storeTel: "",
                storeLogo: "",
                storeLat: 39.908823,
                storeLng: 116.39747,
                storeAddress: "",
                storeCity:"",
                chooseAddress:"",
                businessUrl:"",
                qualificationUrl:[

                ],
                collection:false
            },
            qualificationType,
            category2List:[],
            category3List:[],
            category4List:[],
        }
    },
    mounted() {

    },
    filters: {
        tagEffect(status){
            let obj={
                0:'light',
                "1":'dark',
                "2":'dark',
                "3":'dark',
                "4":'dark',
                "5":"dark"
            }
            return obj[status];
        },
        tagStatus(status){
            let obj={
                "0":'',
                "1":'success',
                "2":'danger',
                "3":'warning',
                "4":'info',
                "5":"warning"
            }
            return obj[status];
        },
        tagStatusStr(status){
            // 状态 0 待审核 1 营业组 2 已关门 3 筹建中 4 暂停营业 5关停
            let obj={
                "0":'待审核',
                "1":'营业中',
                "2":'已关停',
                "3":'筹建中',
                "4":'暂停营业',
                "5":"关停"
            }
            return obj[status];
        },

        qualificationTypeF(value){
            let item = qualificationType.filter(item => {
                return  item.value === value
            });
            return item? item[0].label : '';
        }

    },
    methods: {
        editTime(item){
            this.$refs.storeEditTime.init(item)
        },
        /**
         * 修改状态
         */
        editStatus(item){
            this.$refs.storeEditStatus.init(item)
        },
        /**
         * 打开创建页面
         */
        addStore(){
            this.$refs.storeCreate.initDialog()
        },
        editEdit(){
            if(this.storeInfo){
                this.$refs.storeInfo.init(this.storeInfo)
            }
        },
        /**
         * 获取门店列表
         */
        async getList(){
            let {data}=await getStoreListApi(this.params)
            let rows=data.rows;
            if(rows && rows.length>0){
                this.storeInfo = rows[0]
                let qArr=[];
                this.storeInfo.qualificationUrl.forEach(i=>{
                    if(i.type===1){
                        this.storeInfo.businessUrl=i.img
                    }else{
                        qArr.push({
                            img:i.img,
                            type:i.type
                        })
                    }
                })
                this.storeInfo.qualificationUrl=qArr
            }else{
                this.storeInfo=""
            }

        },
        async getStoreCategoryList(parentId) {
            let {data} = await getStoreOnLineCategoryListApi({
                parentId
            })
            return data;
        },
        async changeCategory(e){
            if (e===2){
                this.category3List = await this.getStoreCategoryList(this.storeInfo.categoryId2)
                this.category4List=[]
            }else{
                this.category4List = await this.getStoreCategoryList(this.storeInfo.categoryId3)
            }
        },
        reset(){
            this.params={
                current:1,
                size:1
            }
        },
        async init(){
            await this.getList()
            this.category2List =await this.getStoreCategoryList(this.storeInfo.categoryId1)
            this.category3List =await this.getStoreCategoryList(this.storeInfo.categoryId2)
            this.category4List =await this.getStoreCategoryList(this.storeInfo.categoryId3)
        }
    },
    created() {
        this.init()
    }
}
