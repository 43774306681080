import service from "@/utils/request"


export function cashOutAddApi(data) {
    return service({
        url: "/offline/cash/out/add",
        method: "post",
        data
    })
}

export function cashOutPageApi(params) {
    return service({
        url: "/offline/cash/out/getPage",
        method: "get",
        params
    })
}
