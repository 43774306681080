<template>
  <div>
    <el-dialog
        title="售后进度"
        :visible.sync="dialogVisible"
        width="40%" appendToBody="true"
    >

      <div v-if="isShowAddAddress" style="display: flex;margin-left: 20px;align-items: baseline">
        <div>
          当前还未设置商品寄回地址
        </div>
        <el-button size="mini" type="primary" round @click="openAddSaasAddress">新增地址</el-button>
      </div>
      <el-card shadow="always" >
        <div style="display:flex;">
          <div>售后服务单号：</div>
          <div>{{currentDetail.id}}</div>
        </div>
      </el-card>

      <el-card shadow="always">
        <div>商品信息</div>
        <div style="display: flex">
          <div>
            <el-image :src="currentDetail.goodsInfo.picUrl" style="width: 100px;height: 100px;" ></el-image>
          </div>
          <div style="margin-left: 10px;display: flex;flex-direction: column; justify-content: center">
            <div>
              {{currentDetail.goodsInfo.skuName}}
            </div>
            <div style="display:flex;">
              <div>数量:</div>
              <div>{{currentDetail.goodsInfo.num}}</div>
            </div>
          </div>
        </div>
      </el-card>

      <el-card shadow="always">
        <div  style="display: flex;flex-direction: column">
          <div style="display: flex">
            <div>申请类型：</div>
            <div style="margin-left: 10px">{{currentDetail.typeDesc}}</div>
          </div>
          <div style="display: flex">
            <div>问题描述：</div>
            <div style="margin-left: 10px">{{currentDetail.questionDetail}}</div>
          </div>
          <div style="display: flex">
            <div>问题图片：</div>
            <div style="margin-left: 10px">
              <el-image v-for="(item,index) in currentDetail.imgUrlList"
                        :key="index" :src="item"
                        :preview-src-list="currentDetail.imgUrlList"
                        style="width: 100px;height: 100px;">
              </el-image>
            </div>
          </div>
        </div>
      </el-card>
      <el-card>
        <div style="display: flex">
          <div>当前服务进度：</div>
          <span>{{currentDetail.statusDesc}}</span>
          <!--          <el-dropdown @command="handleCommand">-->
          <!--          <span class="el-dropdown-link">-->
          <!--            {{ nextStatusDesc }}<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
          <!--          </span>-->
          <!--            <el-dropdown-menu slot="dropdown">-->
          <!--              <el-dropdown-item v-if="currentDetail.goodsInfo.goodsType===1" :disabled="currentDetail.status>5" command="10">提交售后申请到京东</el-dropdown-item>-->
          <!--              <el-dropdown-item v-if="currentDetail.goodsInfo.goodsType!==1" :disabled="currentDetail.status<20 && currentDetail.status>60" command="20">审核完成待收货</el-dropdown-item>-->
          <!--              <el-dropdown-item v-if="currentDetail.goodsInfo.goodsType!==1" :disabled="currentDetail.status<30 && currentDetail.status>60" command="30">收货完成待处理</el-dropdown-item>-->
          <!--              <el-dropdown-item v-if="currentDetail.goodsInfo.goodsType!==1" :disabled="currentDetail.status<30 && currentDetail.status>60" command="40">处理完成（如需退款则等待退款）</el-dropdown-item>-->
          <!--              <el-dropdown-item v-if="currentDetail.goodsInfo.goodsType!==1" :disabled="currentDetail.status<30 && currentDetail.status>60" command="50">待用户确认</el-dropdown-item>-->
          <!--              <el-dropdown-item  :disabled="currentDetail.status>60" command="80">驳回</el-dropdown-item>-->
          <!--            </el-dropdown-menu>-->
          <!--          </el-dropdown>-->
        </div>

      </el-card>
      <el-card v-if="currentDetail.status>=20 && currentDetail.status<70">
        <div  style="display:inline-block;">
          商品寄回地址
          <el-tooltip effect="dark" content="退回的商品将会寄往此处" placement="bottom">
            <i class='el-icon-question'/>
          </el-tooltip>
        </div>
        <el-form style="margin-top: 10px">
          <el-row >
            <el-col :span="24">
              <el-form-item prop="provinceId">
                <el-col :span="5">
                  <el-select disabled v-model="currentDetail.goodsReturnProvinceId"  clearable  placeholder="请选择省" filterable @change="provinceChange">
                    <el-option v-for="(item, index) in provinceList" :key="'a'+index" :label="item.name"
                               :value="item.kid"></el-option>
                  </el-select>
                </el-col>
                <el-col :span="1">&nbsp;</el-col>
                <el-col :span="5">
                  <el-select disabled v-model="currentDetail.goodsReturnCityId" placeholder="请选择市"  filterable @change="cityChange">
                    <el-option v-for="(item, index) in cityList" :key="'b'+index" :label="item.name"
                               :value="item.kid"></el-option>
                  </el-select>
                </el-col>
                <el-col :span="1">&nbsp;</el-col>
                <el-col :span="5">
                  <el-select disabled v-model="currentDetail.goodsReturnAreaId" placeholder="请选择县"  filterable  @change="areaChange">
                    <el-option v-for="(item, index) in areaList" :key="'c'+index" :label="item.name"
                               :value="item.kid"></el-option>
                  </el-select>
                </el-col>
                <el-col :span="1">&nbsp;</el-col>
                <el-col :span="5">
                  <el-select disabled v-model="currentDetail.goodsReturnStreetId"  placeholder="请选择街道" >
                    <el-option value="0" label="无"></el-option>
                    <el-option v-for="(item, index) in streetList" :key="index" :label="item.name"
                               :value="item.kid"></el-option>
                  </el-select>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-input disabled v-model="currentDetail.goodsReturnAddressDetail" placeholder="请输入详细地址"></el-input>
          </el-row>
          <el-row :gutter="1" style="margin-top: 10px">
            <el-col span="12">
              <el-input disabled v-model="currentDetail.goodsReturnName" placeholder="请输入收件人姓名"></el-input>
            </el-col>
            <el-col span="12" >
              <el-input disabled v-model="currentDetail.goodsReturnTel" placeholder="请输入收件人电话"></el-input>
            </el-col>
          </el-row>
        </el-form>

        <div v-if="currentDetail.expressNo" style="display:flex;margin-top: 10px">
          <div>商品已寄回：</div>
          <div>{{currentDetail.expressName + ' ' + currentDetail.expressNo}}</div>
        </div>
      </el-card>
      <el-card v-if="currentDetail.status===80">
        <div>驳回原因：</div>
        <el-input disabled v-model="currentDetail.reply" type="textarea"  placeholder="请说明驳回原因" :autosize="{minRows:2}"></el-input>
      </el-card>
      <el-row style="display:flex;justify-content: space-around;margin-top: 10px">
        <el-button @click="cancelSubmit">关闭</el-button>
        <el-button type="danger" v-if="currentDetail.status==10 || currentDetail.status==5" @click="cantAfterSale">驳回</el-button>
        <el-button type="primary" v-if="currentDetail.status==5" @click="submit2Jd">提交售后申请到京东</el-button>
        <el-button :loading="submitAgreeAfs" type="primary" v-if="currentDetail.goodsInfo.goodsType===2 && currentDetail.status==10" @click="agreeAfs">同意</el-button>
        <el-button type="primary" v-if="currentDetail.goodsInfo.goodsType===2 && currentDetail.status>10 && currentDetail.status<50 && currentDetail.expressNo"
                   @click="needCustomerConfirm">收货并验收完成</el-button>
      </el-row>
    </el-dialog>
    <el-dialog
        title="驳回原因"
        :visible.sync="rejectDialogShow"
        width="30%" appendToBody="true"
    >
      <div class="form-container">
        <el-form style="width: 100%">
          <el-form-item>
            <el-select v-model="currentDetail.reply" filterable placeholder="请选择驳回原因" style="width: 100%">
              <el-option
                  v-for="(item, index) in rejectReasons"
                  :key="index"
                  :label="item.value"
                  :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="rejectDialogShow = false">取消</el-button>
        <el-button type="primary" @click="submit">提交</el-button>
      </span>
    </el-dialog>

    <SaasAddress ref="saasAddress" @getAddressInfo="getGoodsAfsAddress"></SaasAddress>
  </div>
</template>
<script>
import {getOrderAfsDetailApi, submitAfsUpdateStatusApi} from "@/api/lxh/applet/afterSale";
import {getJdRegionList} from "@/api/jdRegionApi";
import {getJdRegionListApi} from "@/api/lxh/applet/addressApi";
import {getDefaultAddressDetailApi} from "@/api/lxh/sassUser/addressApi";
import SaasAddress from "@/components/SaasAddress/index.vue";

export default {
  components:{SaasAddress},
  data(){
    return{
      dialogVisible:false,
      isShowAddAddress:false,
      rejectDialogShow:false,
      currentDetail:{
        goodsInfo:{},
        id:"",
        typeDesc:"",
        questionDetail:"",
        imgUrlList:[],
        statusDesc:"",
        status:"",
        submitApplyStatus:"",
        goodsReturnProvinceId:"",
        goodsReturnCityId:"",
        goodsReturnAreaId:"",
        goodsReturnStreetId:"",
        goodsReturnAddressDetail:"",
        goodsReturnName:"",
        goodsReturnTel:"",
        expressName:"",
        expressNo:"",
        reply:"",
      },
      nextStatus:0,
      nextStatusDesc:"",
      orderAfsStatus: [
        { code: 5, description: "客户已提交"},
        { code: 10, description: "申请中待审核" },
        { code: 20, description: "审核完成待收货" },
        { code: 30, description: "收货完成待处理；" },
        { code: 40, description: "处理完成（如需退款则等待退款）" },
        { code: 50, description: "待用户确认" },
        { code: 60, description: "用户确认完成" },
        { code: 70, description: "取消" },
        { code: 80, description: "驳回" }
      ],
      provinceList: [],
      cityList: [],
      areaList: [],
      streetList: [],
      submitAgreeAfs:false,
      rejectReasons: [
        { value: '您的退货申请超出了规定的退货期限' },
        { value: '商品上存在显著的使用痕迹或已经损坏，因此不符合退货条件'},
        { value: '商品的原包装和附带的所有附件不完整，无法重新销售，不符合退货要求'},
        { value: '我们无法确认商品的购买来源'},
        { value: '商品非质量问题，不支持退货'},
        { value: '该商品属于促销活动产品，根据促销政策不支持常规退货'},
        { value: '定制或个性化商品不支持退货和退款'},
        { value: '由于卫生和安全原因，已拆封的个人用品（如内衣、化妆品）不支持退货'},
        { value: '电子类产品已被激活或注册使用，不符合无理由退货政策'},
        { value: '由于责任不明的运输损伤，暂时无法受理此退货请求'},
        { value: '商品序列号与记录不符，我们无法确认其购买时间和来源'},
        { value: '抱歉！该商品暂不支持退货，如有疑问，请您联系平台客服介入'}
      ],
    }
  },
  methods:{

    init(id){
      this.dialogVisible = true
      this.getAfsDetail(id)
    },
    async getGoodsAfsAddress(){
      let {data} = await getDefaultAddressDetailApi()
      if(!data){
        this.isShowAddAddress = true
      }else{
        this.isShowAddAddress = false
      }
    },
    openAddSaasAddress(){
      this.$refs.saasAddress.init()
    },
    async getAfsDetail(id){
      let {data} = await getOrderAfsDetailApi({id})
      this.currentDetail = data
      this.nextStatus = data.status
      if(data.status<20){
        this.getGoodsAfsAddress()
      }
      const res = await getJdRegionList({pId:0})
      this.provinceList = res.data
      if(this.currentDetail.goodsReturnProvinceId){
        const regionQuery = {};
        regionQuery.pId = this.currentDetail.goodsReturnProvinceId
        const {data} = await getJdRegionList(regionQuery);
        this.cityList = data
      }
      if(this.currentDetail.goodsReturnCityId){
        const regionQuery = {};
        regionQuery.pId = this.currentDetail.goodsReturnCityId
        const {data} = await getJdRegionList(regionQuery);
        this.areaList = data
      }
      if(this.currentDetail.goodsReturnAreaId){
        const regionQuery = {};
        regionQuery.pId = this.currentDetail.goodsReturnAreaId
        const {data} = await getJdRegionList(regionQuery);
        this.streetList = data
      }
      if(this.currentDetail.goodsReturnStreetId){
        const regionQuery = {};
        regionQuery.pId = this.currentDetail.goodsReturnStreetId
        const {data} = await getJdRegionList(regionQuery);
        this.streetList = data
      }else{
        this.currentDetail.goodsReturnStreetId=""
      }
      this.getCurrentStatusDesc()
    },
    getCurrentStatusDesc(){
      let currentStatus = this.nextStatus
      this.orderAfsStatus.forEach(item=>{
        if(item.code === currentStatus){
          if(currentStatus===10 && this.currentDetail.goodsInfo.goodsType==1){
            this.nextStatusDesc = "提交售后申请到京东"
          }else{
            this.nextStatusDesc = item.description
          }
        }
      })
    },
    handleCommand(command) {
      this.nextStatus = command*1
      this.getCurrentStatusDesc()
    },
    async getProvinceRegionList() {
      const regionQuery = {};
      regionQuery.pId = 0
      const {data} = await getJdRegionListApi(regionQuery);
      this.provinceList = data
    },
    async provinceChange(kid) {
      if(kid==null || kid==""){
        this.cityList = []
        this.areaList = []
        this.streetList=[]
        this.currentDetail.goodsReturnCityId = ""
        this.currentDetail.goodsReturnAreaId = ""
        this.currentDetail.goodsReturnStreetId = ""
        return
      }
      const regionQuery = {};
      regionQuery.pId = kid
      const {data} = await getJdRegionList(regionQuery);
      this.cityList = data
      this.areaList = []
      this.streetList=[]
      this.currentDetail.goodsReturnCityId = ""
      this.currentDetail.goodsReturnAreaId = ""
      this.currentDetail.goodsReturnStreetId = ""
    },
    async cityChange(kid) {
      const regionQuery = {};
      regionQuery.pId = kid
      const {data} = await getJdRegionList(regionQuery);
      this.areaList = data
      this.streetList=[]
      this.currentDetail.goodsReturnAreaId = ""
      this.currentDetail.goodsReturnStreetId = ""
    },
    async areaChange(kid){
      const regionQuery = {};
      regionQuery.pId = kid
      const {data} = await getJdRegionList(regionQuery);
      this.streetList = data
      this.currentDetail.goodsReturnStreetId = ""
    },
    cancelSubmit(){
      this.dialogVisible = false
    },
    cantAfterSale(){
      this.nextStatus = 80
      this.rejectDialogShow = true
       // this.$prompt('请输入驳回原因', '提示', {
       //   confirmButtonText: '确定',
       //   cancelButtonText: '取消',
       //   inputErrorMessage: '请输入驳回原因'
       // }).then(({ value }) => {
       //   this.currentDetail.reply = value
       //   this.submit()
       // })
    },
    submit2Jd(){
      this.nextStatus = 10
      this.submit()
    },
    async agreeAfs(){
      let {data} = await getDefaultAddressDetailApi()
      if (!data){
        this.$message({
          message: "请先设置商品退货地址",
          type: "warning",
        })
        return
      }else{
        this.currentDetail.goodsReturnProvinceId = data.provinceId
        this.currentDetail.goodsReturnCityId = data.cityId
        this.currentDetail.goodsReturnAreaId = data.areaId
        this.currentDetail.goodsReturnStreetId = data.streetId
        this.currentDetail.goodsReturnName = data.name
        this.currentDetail.goodsReturnTel = data.tel
        this.currentDetail.goodsReturnAddressDetail = data.addressDetail
      }
      this.nextStatus = 20
      this.submitAgreeAfs = true
      await this.submit()
      this.submitAgreeAfs = false
    },
    needCustomerConfirm(){
      this.nextStatus = 50
      this.submit()
    },

    async submit(){

      if(this.nextStatus==20){
        if(this.currentDetail.goodsReturnProvinceId==""
            || this.currentDetail.goodsReturnCityId==""
            || this.currentDetail.goodsReturnAreaId==""
            || this.currentDetail.goodsReturnAddressDetail==""
            || this.currentDetail.goodsReturnName==""
            || this.currentDetail.goodsReturnTel==""){
          this.$message({
            message: "请完善退货地址信息",
            type: "warning",
          });
          return
        }
      }
      if(this.nextStatus===80){
        if(!this.currentDetail.reply || !this.currentDetail.reply.trim()){
          this.$message({
            message: "请输入驳回原因",
            type: "warning",
          })
          return
        }
      }

      let param = {
        id:this.currentDetail.id,
        status:this.nextStatus,
        goodsReturnProvinceId:this.currentDetail.goodsReturnProvinceId,
        goodsReturnCityId:this.currentDetail.goodsReturnCityId,
        goodsReturnAreaId:this.currentDetail.goodsReturnAreaId,
        goodsReturnStreetId:this.currentDetail.goodsReturnStreetId,
        goodsReturnAddressDetail:this.currentDetail.goodsReturnAddressDetail,
        goodsReturnName:this.currentDetail.goodsReturnName,
        goodsReturnTel:this.currentDetail.goodsReturnTel,
        reply:this.currentDetail.reply
      }

      let res = await submitAfsUpdateStatusApi(param)
      if(res.code==200){
        this.$message({
          message: "操作成功",
          type: "success",
          duration:2000,
          onClose:()=>{
            this.dialogVisible = false
            this.$emit("getAfsList")
          }
        });
      }else{
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
      this.rejectDialogShow = false
    }
  },
  created() {

  }
}

</script>

<style scoped>
.form-container {

  display: flex;

  justify-content: center; /* 水平居中 */

  align-items: center; /* 垂直居中 */

  height: 100%; /* 确保容器的高度适应对话框 */

}
.el-select-dropdown__item {

  white-space: normal; /* 允许文本换行 */

  word-wrap: break-word; /* 长单词换行 */

}

.el-select-dropdown {

  max-width: 100%; /* 确保下拉菜单宽度适应文本 */

}
</style>