<template>
  <el-dialog
      title="编辑店铺"
      :visible.sync="dialogVisible"
      width="80%"
      top="10px"
      :before-close="handleClose">
    <div style="min-height: 80vh">
      <el-row :gutter="20" style="margin-top: 10px">
        <el-col :span="5">
          <div class="card card-flush py-4">
            <div class="card-header">
              <div class="card-title">
                <h2 class="required">店铺Logo</h2>
              </div>
            </div>
            <div class="card-body text-center pt-0" @click="openMaterial('logo')">

              <div data-kt-image-input="true" class="image-input image-input-outline blankPng"
                   style="background-image: url(&quot;assets/media/avatars/blank.png&quot;);">
                <div class="image-input-wrapper w-125px h-125px"
                     :style="`background-image:url('${storeInfo.storeLogo}')`"></div>
                <label data-bs-original-title="上传图像" data-bs-toggle="tooltip" data-kt-image-input-action="change"
                       title="" class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow">
                  <i class="bi bi-pencil-fill fs-7"></i>
                </label>
                <span data-bs-original-title="取消" data-bs-toggle="tooltip" data-kt-image-input-action="cancel"
                      title="" class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow">
                    <i class="bi bi-x fs-2"></i>
                </span>
              </div>
              <div class="text-muted fs-7"> 设置店铺头图。仅上传 *.png、*.jpg 和 *.jpeg 图片文件。</div>
            </div>
          </div>
          <div class="card card-flush py-4" style="margin-top:20px;">
            <div class="card-body">
              <label
                  class="fs-6 form-label fw-bolder text-dark required">
                店铺营业执照
              </label>
              <div style="margin-bottom: 10px" v-if="storeInfo.businessUrl">
                <img :src="storeInfo.businessUrl" width="214" height="116" alt=""/>
                <div
                    style="width: 214px; height: 30px; line-height: 30px; padding: 0 30px; color: rgb(255, 255, 255); display: flex; justify-content: space-around; background: rgb(45, 157, 209);">
                  <div @click="openMaterial('business')">
                    <div tabindex="0" class="el-upload el-upload--text">
                      <div style="cursor: pointer; color: rgb(255, 255, 255);font-size: 12px"> 重新上传</div>
                    </div>
                  </div>
                  <div> |</div>
                  <div style="cursor: pointer; color: rgb(255, 255, 255);font-size: 12px"> 预览</div>
                </div>
              </div>
              <div
                  v-else
                  @click="openMaterial('business')"
                  style="width: 214px; height: 30px; line-height: 30px; padding: 0 30px; color: rgb(255, 255, 255); display: flex; justify-content: space-around; background: rgb(45, 157, 209);">
                <div>
                  <div tabindex="0" class="el-upload el-upload--text">
                    <div style="cursor: pointer; color: rgb(255, 255, 255);font-size: 12px">上传店铺营业执照</div>
                  </div>
                </div>
              </div>
              <label
                  class="fs-6 form-label fw-bolder text-dark required">
                店铺资质
              </label>
              <div style="margin-bottom: 5px" v-for="(item,index) in storeInfo.qualificationUrl" :key="index">
                <el-select v-model="item.type" placeholder="请选择" size="mini">
                  <el-option
                      v-for="item in qualificationType"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <div style="position: relative">
                  <div
                      style="position: absolute;top: 0;left: 0;background: #2D9DD1;color: #fff;padding: 0 10px;border-radius: 0 0 10px 0;font-size: 10px">
                    {{ item.type | qualificationTypeF }}
                  </div>
                  <img :src="item.img" width="214" height="116" alt=""/>
                  <div
                      style="width: 214px; height: 30px; line-height: 30px; padding: 0 30px; color: rgb(255, 255, 255); display: flex; justify-content: space-around; background: rgb(45, 157, 209);">
                    <div @click="openMaterial('qualification',index)">
                      <div tabindex="0" class="el-upload el-upload--text">
                        <div style="cursor: pointer; color: rgb(255, 255, 255);font-size: 12px"> 重新上传</div>
                      </div>
                    </div>
                    <div>|</div>
                    <div style="cursor: pointer; color: rgb(255, 255, 255);font-size: 12px"> 预览</div>
                    <div>|</div>
                    <div style="cursor: pointer; color: rgb(255, 255, 255);font-size: 12px"> 移除</div>
                  </div>
                </div>
              </div>
              <div
                  style="color: #fff;text-align: center;background: rgb(45, 157, 209);width: 214px;margin-top: 10px;cursor: pointer;height: 30px;line-height: 30px"
                  @click="addQualification">
                <i class="el-icon-plus"></i>添加更多资质
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="19">
          <div class="flex-column flex-row-fluid gap-7 gap-lg-10" style="margin-right: 10px;border-radius: 15px">
            <div class="tab-content">
              <div id="kt_ecommerce_add_product_general" role="tab-panel"
                   class="tab-pane fade show active">
                <div class="d-flex flex-column gap-7 gap-lg-10">
                  <div class="card card-flush py-4"
                       style="border-radius: 0 0 15px 15px !important; ">
                    <div class="card-header">
                      <div class="card-title">
                        <h2 class="required">店铺名称</h2>
                      </div>
                    </div>
                    <div class="card-body pt-0">
                      <div class="mb-10 fv-row d-flex" style="justify-content: space-between">
                        <input type="text" name="product_name" placeholder="店铺名称" v-model="storeInfo.storeName"
                               class="form-control form-control-lg form-control-solid " style="width: 41%">
                        <select data-placeholder="全部" class="form-select form-select-solid" style="width: 19%" v-model="storeInfo.categoryId2" @change="changeCategory(2)">
                          <option :value="item.id" v-for="(item,index) in category2List" :key="index">{{ item.categoryName }}</option>
                        </select>
                        <select  data-placeholder="全部" class="form-select form-select-solid" style="width: 19%" v-model="storeInfo.categoryId3" @change="changeCategory(3)">
                          <option :value="item.id" v-for="(item,index) in category3List" :key="index">{{ item.categoryName }}</option>
                        </select>
                        <select  data-placeholder="全部" class="form-select form-select-solid" style="width: 19%" v-model="storeInfo.categoryId4" @change="changeCategory(4)">
                          <option :value="item.id" v-for="(item,index) in category4List" :key="index">{{ item.categoryName }}</option>
                        </select>

                      </div>
                      <div class="card-body border-top" style="padding-left: 0;padding-right: 0">
                        <div class="row mb-12">

                          <label class="col-lg-1 col-form-label fw-bold fs-6">
                            <span class="required">店铺电话</span>
                          </label>
                          <div class="col-lg-5 fv-row">
                            <input type="text"
                                   placeholder="请输入店铺电话"
                                   v-model="storeInfo.storeTel"
                                   class="form-control form-control-lg form-control-solid">
                          </div>
                        </div>
                        <div class="row mb-10" v-if="false">

                          <label class="col-lg-1 col-form-label fw-bold fs-6">
                          <span class="required">
                            店铺地址
                          </span>
                          </label>
                          <div class="col-lg-5 fv-row" id="storeAddress">
                            <el-autocomplete
                                popper-class="my-autocomplete"
                                style="width: 100%;color: #4B5675;background: #F9F9F9;border: none"
                                v-model="storeInfo.chooseAddress"
                                :fetch-suggestions="querySearchAsync"
                                @select="handleSelect"
                                placeholder="请输入店铺地址"
                            >
                              <template slot-scope="{ item }">
                                <div class="name">{{ item.title }}</div>
                                <span class="addr" style=" font-size: 12px;
      color: #b4b4b4;">{{ item.address }}</span>
                              </template>
                            </el-autocomplete>
                          </div>
                          <label class="col-lg-1 col-form-label fw-bold fs-6">
                          <span class="required">
                            详细地址
                          </span>
                          </label>
                          <div class="col-lg-5 fv-row" id="storeAddress">
                            <input type="text"
                                   placeholder="请输入店铺详细地址"
                                   v-model="storeInfo.storeAddress"
                                   class="form-control form-control-lg form-control-solid">
                          </div>
                        </div>
                        <div id="containerStoreEditMap" style="border-radius: 15px"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <span slot="footer" class="dialog-footer">
      <sj-button type="secondary" @click="dialogVisible = false">取消</sj-button>
      <sj-button type="success" @click="saveStore" style="margin-left: 20px">保存</sj-button>
    </span>
    <material-component ref="MaterialComponent" @useImg="useImg"></material-component>
  </el-dialog>
</template>
<script>
import {mixins} from "@/views/Store/info/mixins";

export default {
  name: "StoreInfo",
  components: {},
  mixins: [mixins]

}
</script>
<style>
.my-autocomplete li {
  line-height: normal;
  padding: 7px;
}

.my-autocomplete li .name {
  text-overflow: ellipsis;
  overflow: hidden;
}

.my-autocomplete li .addr {
  font-size: 12px;
  color: #b4b4b4;
}

.my-autocomplete li .highlighted .addr {
  font-size: 12px;
  color: #b4b4b4;
}

#storeAddress {
  border-radius: .85rem !important;
}

#storeAddress > div > div {
  border-radius: .85rem !important;
}

#storeAddress input {
  background: #F9F9F9;
  border: none;
  border-radius: .85rem !important;
}

#container {
  /*地图(容器)显示大小*/
  width: 1200px;
  height: 400px;
}

.store_time_picker {
  background: rgb(249, 249, 249);
  border: none;
}

.store_time_picker .el-range-separator {
  width: 30px !important;
  background: rgb(249, 249, 249);
}

.store_time_picker .el-range-input {
  background: rgb(249, 249, 249);
}

#cascader {
  border-radius: .85rem;
}

#cascader > .el-input > input::placeholder {
  color: #99A1B7;

}

#cascader > .el-input > input {
  border: none;
  width: 100%;
  background: rgb(249, 249, 249);
  border-radius: .85rem;
}

.store_openingHours .el-input__inner {
  border-radius: 0.75rem;
}
</style>
<style scoped>
.store_openingHours {
  border-radius: .75rem;
}

.el-input__inner {
  border-radius: 0.75rem;
}

.choose_store_div {
  margin-top: 10px;
  height: 60px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}

.choose_store_div_right {
  display: flex;
}

.store_info_div {
  margin-top: 20px;
  background-color: #fff;
  padding: 0 10px;
  padding-bottom: 20px;
}

.div_title {
  font-weight: bold;
}

.div_info {
  padding: 0 20px;

}

::v-deep .el-date-editor .el-range-separator {
  display: inline-block;
  height: 100%;
  /* padding: 0 5px; */
  margin: 0;
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  width: 7%;
  color: #303133;
}
</style>
