<template>

  <!--begin::Col-->
  <div class="col-xl-8 mb-xl-8">
    <div class="card h-md-100">
      <div class="card-header align-items-center border-0">
        <h3 class="fw-bold text-gray-900 m-0">平台优选</h3>
      </div>
      <div class="card-body pt-2">
        <ul class="nav nav-pills nav-pills-custom mb-3">
          <li class="nav-item mb-3 me-3 me-lg-6" v-for="(item, index) in navList" :key="index">
            <a class="nav-link d-flex justify-content-between flex-column flex-center overflow-hidden w-80px h-85px py-4"
               :class="index===0?'active':''"
               data-bs-toggle="pill" :href="`#kt_stats_widget_2_tab_${index+1}`">
              <div class="nav-icon">
                <img alt="" :src="item.imagesSrc" class=""/>
              </div>
              <span class="nav-text text-gray-700 fw-bold fs-7 lh-1">
                        {{ item.title.substring(0, 4) }}
                    </span>
              <span class="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane fade" :id="`kt_stats_widget_2_tab_${index+1}`" v-for="(item, index) in navList"
               :key="index" :class="index===0?'show active':''">
            <div class="table-responsive">
              <table class="table table-row-dashed align-middle gs-0 gy-4 my-0">
                <thead>
                <tr class="fs-7 fw-bold text-gray-500 border-bottom-0">
                  <th class="">商品信息</th>
                  <th class="pe-0 text-center min-w-100px">销售价</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(i, index) in item.list" :key="index">
                  <td>
                    <div class="d-flex align-items-center">
                      <a href="javascript:void(0);" class="symbol symbol-50px">
                        <img class="symbol-label lozad" :src="i.imagesUrl"/>
                        <!--                        <img class="symbol-label lozad" v-if="i.imagesUrl.indexOf('http') == 0"-->
                        <!--                             :src="`${i.imagesUrl}`"/>-->
                        <!--                        <img class="symbol-label lozad" v-else :src="`https://www.3jzc.cn${i.imagesUrl}`"/>-->
                      </a>
                      <div class="ms-3">
                        <a href="javascript:void(0);" class="text-gray-800 text-hover-primary fs-6 fw-bold" style="white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;display: block" :title="i.title"
                           data-kt-ecommerce-product-filter="product_name">{{ i.title }}</a>
                      </div>
                    </div>
                  </td>
                  <td class="text-center pe-0">
                    <span class="text-gray-800 fw-bold d-block fs-6">{{ i.price | format }}</span>
                  </td>
                </tr>
                </tbody>
                <!--end::Table body-->
              </table>
              <!--end::Table-->
            </div>
            <!--end::Table container-->
          </div>

        </div>
        <!--end::Tab Content-->
      </div>
      <!--end: Card Body-->
    </div>
  </div>

</template>
<script>
import GoodInfoComponent from "@/components/GoodInfoComponent/index.vue";
import {getSaasGoodApi, getSaasGoodsInfoApi} from "@/api/home";

export default {
  name: "PlatformRecommendation",
  components: {GoodInfoComponent},
  data() {
    return {
      navList: [
        {
          title: '美食',
          imagesSrc: 'https://xysy-saas.oss-cn-hangzhou.aliyuncs.com/lianXiangHui/dashboard/%E9%A4%90%E9%A5%AE%E7%BE%8E%E9%A3%9F.png',
          list: [
            {
              "id": "100014930464",
              "title": "大叹号烤肉朝鲜族料理【夏日限定】",
              "price": "99.00",
              "imagesUrl": "https://xysy-saas.oss-cn-hangzhou.aliyuncs.com/lianxianghui/goods/%E7%BE%8E%E9%A3%9F/%E5%A4%A7%E5%8F%B9%E5%8F%B7%E7%83%A4%E8%82%89%E6%9C%9D%E9%B2%9C%E6%97%8F%E6%96%99%E7%90%86%E3%80%90%E5%A4%8F%E6%97%A5%E9%99%90%E5%AE%9A%E3%80%9199%E5%85%83.jpg",
              "disPrice": "9.9",
              "discount": "0.790",
              "isJd": "1"
            },
            {
              "id": "100014930464",
              "title": "翡翠青椒豆花烤鱼",
              "price": "149.00",
              "imagesUrl": "https://xysy-saas.oss-cn-hangzhou.aliyuncs.com/lianxianghui/goods/%E7%BE%8E%E9%A3%9F/%E7%BF%A1%E7%BF%A0%E9%9D%92%E6%A4%92%E8%B1%86%E8%8A%B1%E7%83%A4%E9%B1%BC%20149%E5%85%83.jpg",
              "disPrice": "9.9",
              "discount": "0.790",
              "isJd": "1"
            },
            {
              "id": "100014930464",
              "title": "麦旋风买1送1（奥利奥口味+草莓口味",
              "price": "14.00",
              "imagesUrl": "https://xysy-saas.oss-cn-hangzhou.aliyuncs.com/lianxianghui/goods/%E7%BE%8E%E9%A3%9F/%E9%BA%A6%E6%97%8B%E9%A3%8E%E4%B9%B01%E9%80%811%EF%BC%88%E5%A5%A5%E5%88%A9%E5%A5%A5%E5%8F%A3%E5%91%B3%2B%E8%8D%89%E8%8E%93%E5%8F%A3%E5%91%B3%EF%BC%89%2014%E5%85%83.jpg",
              "disPrice": "9.9",
              "discount": "0.790",
              "isJd": "1"
            }
          ]
        },
        {
          title: '美容美发',
          imagesSrc: 'https://xysy-saas.oss-cn-hangzhou.aliyuncs.com/lianXiangHui/dashboard/%E7%BE%8E%E5%AE%B9%E7%BE%8E%E5%8F%91.png',
          list: [
            {
              "id": "100014930464",
              "title": "【夏日狂欢】设计师欧莱雅产品烫发",
              "price": "223.00",
              "imagesUrl": "https://xysy-saas.oss-cn-hangzhou.aliyuncs.com/lianxianghui/goods/%E7%BE%8E%E5%AE%B9%E7%BE%8E%E5%8F%91/%E3%80%90%E5%A4%8F%E6%97%A5%E7%8B%82%E6%AC%A2%E3%80%91%E8%AE%BE%E8%AE%A1%E5%B8%88%E6%AC%A7%E8%8E%B1%E9%9B%85%E4%BA%A7%E5%93%81%E7%83%AB%E5%8F%91%20223%E5%85%83.jpg",
              "disPrice": "119.7",
              "discount": "0.790",
              "isJd": "1"
            },{
              "id": "100014930464",
              "title": "【盛夏美甲来袭】全贴款式不限",
              "price": "86.00",
              "imagesUrl": "https://xysy-saas.oss-cn-hangzhou.aliyuncs.com/lianxianghui/goods/%E7%BE%8E%E5%AE%B9%E7%BE%8E%E5%8F%91/%E3%80%90%E7%9B%9B%E5%A4%8F%E7%BE%8E%E7%94%B2%E6%9D%A5%E8%A2%AD%E3%80%91%E5%85%A8%E8%B4%B4%E6%AC%BE%E5%BC%8F%E4%B8%8D%E9%99%90%2086%E5%85%83.jpg",
              "disPrice": "119.7",
              "discount": "0.790",
              "isJd": "1"
            },{
              "id": "100014930464",
              "title": "清痘祛痘 面部清颜60分钟",
              "price": "188.00",
              "imagesUrl": "https://xysy-saas.oss-cn-hangzhou.aliyuncs.com/lianxianghui/goods/%E7%BE%8E%E5%AE%B9%E7%BE%8E%E5%8F%91/%E6%B8%85%E7%97%98%E7%A5%9B%E7%97%98%20%E9%9D%A2%E9%83%A8%E6%B8%85%E9%A2%9C60%E5%88%86%E9%92%9F%20188%E5%85%83.jpg",
              "disPrice": "119.7",
              "discount": "0.790",
              "isJd": "1"
            }
          ]
        },
        {
          title: '休闲娱乐',
          imagesSrc: 'https://xysy-saas.oss-cn-hangzhou.aliyuncs.com/lianXiangHui/dashboard/%E4%BC%91%E9%97%B2%E5%A8%B1%E4%B9%90.png',
          list: [
            {
              "id": "100014930464",
              "title": "【机关天花板】镇灵村密室逃脱",
              "price": "260.00",
              "imagesUrl": "https://xysy-saas.oss-cn-hangzhou.aliyuncs.com/lianxianghui/goods/%E4%BC%91%E9%97%B2%E5%A8%B1%E4%B9%90/qweqeqwe.jpg",
              "disPrice": "119.7",
              "discount": "0.790",
              "isJd": "1"
            },  {
              "id": "100014930464",
              "title": "香薰石膏DIY",
              "price": "35.00",
              "imagesUrl": "https://xysy-saas.oss-cn-hangzhou.aliyuncs.com/lianxianghui/goods/%E4%BC%91%E9%97%B2%E5%A8%B1%E4%B9%90/xiangxun.jpg",
              "disPrice": "119.7",
              "discount": "0.790",
              "isJd": "1"
            },  {
              "id": "100014930464",
              "title": "【质享·兰亭】足疗70分钟",
              "price": "169.00",
              "imagesUrl": "https://xysy-saas.oss-cn-hangzhou.aliyuncs.com/lianxianghui/goods/%E4%BC%91%E9%97%B2%E5%A8%B1%E4%B9%90/zuliao.jpg",
              "disPrice": "119.7",
              "discount": "0.790",
              "isJd": "1"
            }
          ]
        },
        {
          title: '数码',
          imagesSrc: 'https://yigsj.oss-cn-hangzhou.aliyuncs.com/sj/category/sjsm.png',
          list: [
              {
                "id": "100015592311",
                "title": "REMAX无线耳机",
                "price": "189.00",
                "imagesUrl": "https://img13.360buyimg.com/n0/jfs/t1/159710/16/27583/47761/617934b9Eb5b1677d/bcdfe122e44a4b97.jpg",
                "disPrice": "111.00",
                "discount": "0.587",
                "isJd": "1"
              },
              {
                "id": "100045858257",
                "title": "美的（Midea）体重秤MO-CW7",
                "price": "129.00",
                "imagesUrl": "https://img13.360buyimg.com/n0/jfs/t1/210509/29/27259/6157/635f9112E01ae0025/b5273aeb30d960d1.jpg",
                "disPrice": "59.80",
                "discount": "0.464",
                "isJd": "1"
              },
              {
                "id": "100013152710",
                "title": "华为智选 欧普智能护眼台灯Pro 国AA级防蓝光学生儿童学习读写卧室宿舍书房床头LED智能开关远程控制 皓月银",
                "price": "259.00",
                "imagesUrl": "https://img13.360buyimg.com/n0/jfs/t1/118311/29/34336/55069/64171491F6480a597/712d3927169ecc93.jpg",
                "disPrice": "254.90",
                "discount": "0.984",
                "isJd": "1"
              }
          ]
        },
        {
          title: '生鲜',
          imagesSrc: 'https://yigsj.oss-cn-hangzhou.aliyuncs.com/sj/category/mwsx.png',
          list: [
            {
              "id": "100012580516",
              "title": "百吉福（MILKANA） 一杯芝士B.Duck小黄鸭定制 原味 70g*6杯 冷藏 甜品休闲零食",
              "price": "42.90",
              "imagesUrl": "https://img13.360buyimg.com/n0/jfs/t1/134888/2/35246/89625/6491074cF8365e078/0bbac38458534dbc.jpg",
              "disPrice": "42.90",
              "discount": "1.000",
              "isJd": "1"
            },
            {
              "id": "2341185",
              "title": "农夫山泉17.5°NFC橙汁果汁饮料（冷藏型）100%鲜果冷压榨果汁 950ml/瓶",
              "price": "28.00",
              "imagesUrl": "https://img13.360buyimg.com/n0/jfs/t1/111686/4/34091/16471/64721b60Fbcc06d6b/64d0ba45f0af9e63.png",
              "disPrice": "24.30",
              "discount": "0.868",
              "isJd": "1"
            },
            {
              "id": "100002137829",
              "title": "京鲜生 烟台红富士苹果 12个 净重4斤以上 生鲜水果",
              "price": "99.90",
              "imagesUrl": "https://img13.360buyimg.com/n0/jfs/t25924/98/790385273/547797/e0c3ca7d/5b7cb4b6Nce1debe2.jpg",
              "disPrice": "36.00",
              "discount": "0.360",
              "isJd": "1"
            }
          ]
        },
        {
          title: '家具日用',
          imagesSrc: 'https://yigsj.oss-cn-hangzhou.aliyuncs.com/sj/category/jjry.png',
          list:[
            {
              "id": "100030618541",
              "title": "惠寻 京东自有品牌 10骨全自动雨伞 晴雨两用加大遮阳伞黑胶伞 紫色",
              "price": "69.90",
              "imagesUrl": "https://img13.360buyimg.com/n0/jfs/t1/172778/26/32235/4939/637bf619E989ecdc8/7fd0d061812c592d.png",
              "disPrice": "21.80",
              "discount": "0.312",
              "isJd": "1"
            },
            {
              "id": "1945624",
              "title": "绿之源 天然香樟木球20粒装（1.8cm）家具书桌衣柜防霉防潮蛀虫樟木条",
              "price": "8.90",
              "imagesUrl": "https://img13.360buyimg.com/n0/jfs/t1/120824/31/16782/132460/5f9cc63aE9ac89d8a/e963612959dc1963.jpg",
              "disPrice": "5.00",
              "discount": "0.562",
              "isJd": "1"
            },
            {
              "id": "100009801015",
              "title": "京东京造 固体空气清新剂卫生间厕所除臭衣柜除味卧室香氛柠檬+绿茶8个装",
              "price": "26.90",
              "imagesUrl": "https://img13.360buyimg.com/n0/jfs/t1/237945/1/9324/92171/658d4c1cFfefe8617/9847279e68013f76.jpg",
              "disPrice": "22.90",
              "discount": "0.851",
              "isJd": "1"
            }
          ]
        },
        {
          title: '家用电器',
          imagesSrc: 'https://yigsj.oss-cn-hangzhou.aliyuncs.com/sj/category/tjjd.png',
          list:[
            {
              "id": "100014930464",
              "title": "美菱（MELING）ZMD安心系列茶吧机 家用多功能智能遥控温热型立式饮水机 办公室饮水器 MY-YT908＊",
              "price": "399.00",
              "imagesUrl": "https://img13.360buyimg.com/n0/jfs/t1/83224/38/25798/121384/64930ee3Fb3230f4a/f9f9102e84bb4d80.jpg",
              "disPrice": "279.00",
              "discount": "0.790",
              "isJd": "1"
            },
            {
              "id": "100006078599",
              "title": "摩飞电器（Morphyrichards）MR1032多功能锅厨具7件套",
              "price": "169.00",
              "imagesUrl": "https://img13.360buyimg.com/n0/jfs/t1/104137/40/9781/124344/5e11a312E783b200f/32ced93d169bc522.jpg",
              "disPrice": "74.00",
              "discount": "0.591",
              "isJd": "1"
            },
            {
              "id": "100013211430",
              "title": "飞利浦（PHILIPS）男士电动剃须刀干湿双剃刮胡刀3000系列升级款S3203/08（一键加速）",
              "price": "469.00",
              "imagesUrl": "https://img13.360buyimg.com/n0/jfs/t1/65139/23/5818/168670/5d3fb9f4E8c7e6f48/4e9909a28959dbe2.jpg",
              "disPrice": "469.00",
              "discount": "1.000",
              "isJd": "1"
            }
          ]
        },
        {
          title: '家用清洁',
          imagesSrc: 'https://yigsj.oss-cn-hangzhou.aliyuncs.com/sj/category/jtqj.png',
          list:[
            {
              "id": "1073455",
              "title": "舒洁（Kleenex）抽纸纸巾 小熊印花软抽纸抽 120抽*16包",
              "price": "66.90",
              "imagesUrl": "https://img13.360buyimg.com/n0/jfs/t1/141606/17/24057/127573/61c00e06E71ed1c88/9d298006ac8cca6a.jpg",
              "disPrice": "54.60",
              "discount": "0.841",
              "isJd": "1"
            },
            {
              "id": "100035456214",
              "title": "艾逸瑶选原生木浆蓝色梦想300张4包软纸抽AYYX018-4",
              "price": "39.90",
              "imagesUrl": "https://img13.360buyimg.com/n0/jfs/t1/147350/30/23610/138620/6232e564E31dc55fe/b835c2367d2b507a.jpg",
              "disPrice": "9.90",
              "discount": "0.256",
              "isJd": "1"
            },
            {
              "id": "100018018816",
              "title": "京东京造 75%酒精湿巾独立包装*50片【DK】",
              "price": "29.90",
              "imagesUrl": "https://img13.360buyimg.com/n0/jfs/t1/158812/8/4287/94291/6007dcc3E17f8f8ce/558f193d900d1a56.jpg",
              "disPrice": "7.50",
              "discount": "0.259",
              "isJd": "1"
            }
          ]
        }
      ]
    };
  },
  methods: {
    openGoodInfo(id, isJD) {
      this.$refs.goodInfo.open();
      this.$refs.goodInfoComponent.openGoodsInfo(id, isJD);
    },
    async getSaasList() {
      // let {data} = await getSaasGoodApi();
      // this.navList = []
      // data.splice(8)
      // data.forEach(async (e) => {
      //   let {data} = await getSaasGoodsInfoApi({
      //     categoryId: e.id
      //   })
      //   this.navList.push({
      //     title: e.title,
      //     imagesSrc: "https://yigsj.oss-cn-hangzhou.aliyuncs.com/sj/category/" + e.imgSrc,
      //     list: data
      //   })

      // })
    },
  },
  mounted() {
    this.getSaasList()
  }
}
</script>
