<template>
  <div style="min-height: 80vh">

    <div class="card bg-light shadow-sm" style="background: #fff;margin-top: 10px;border-radius: 15px">
      <div class="card-body collapsible cursor-pointer rotate"
           style="background: #fff;border-radius: 15px;">
        <div>
          <div class="d-flex align-items-center">
            选择门店
            <el-select v-model="value" class="store_openingHours" placeholder="全部门店" size="mini" style="margin-left: 10px"
                       @change="storeChange">
              <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.storeName"
                  :value="item.id">
              </el-option>
            </el-select>
            <div style="display: flex;align-items: center;margin-left: 16px">
              <div style="margin-right: 10px">
                收益时间:
              </div>
              <div>
                <el-date-picker
                    v-model="value2"
                    size="mini"
                    type="datetimerange"
                    :picker-options="pickerOptions"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    align="right">
                </el-date-picker>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex-column flex-row-fluid gap-7 gap-lg-10" style="border-radius: 15px;margin-top: 10px">
      <div class="tab-content">
        <div class="tab-pane fade show active">
          <div class="d-flex flex-column gap-7 gap-lg-10">
            <div class="card" style="border-radius:15px">

              <div class="card-body" style="padding-top: 4px;">
                <div style="margin-top: 0;min-height: 54vh">
                  <div class="table-responsive">
                    <table id="kt_ecommerce_products_table"
                           class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer"
                    >
                      <thead>
                      <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                        <th class="text-center">
                          订单编号
                        </th>
                        <th>类型
                        </th>
                        <th>
                          收益时间
                        </th>
                        <th>金额
                        </th>
                        <th>余额
                        </th>
                        <th>门店
                        </th>
                        <th class="text-center">
                          操作
                        </th>
                      </tr>
                      </thead>
                      <tbody class="fw-semibold text-gray-600">
                      <tr v-for="(item,index) in rows" :key="index">
                        <td style="font-size: 10px;" class="text-center">
                          {{ item.orderNo }}
                        </td>
                        <td>
                          {{ item.typeStr }}
                        </td>
                        <td>
                          {{ item.createTime }}
                        </td>
                        <td v-if="item.type === 4 || item.type === 5">
                          {{ item.settlingPrice * -1 | format }}
                        </td>
                        <td v-else>
                          {{ item.settlingPrice | format }}
                        </td>
                        <td style="color: rgb(233, 26, 26)">
                          {{ item.balance | format }}
                        </td>
                        <td>
                          {{ item.storeName }}
                        </td>
                        <td class="text-center" style="width: 200px">
                          <el-link type="primary" v-if="item.orderNo !== -1" @click="$router.push('/order?orderNo=' + item.orderNo)">明细</el-link>
                        </td>
                      </tr>
                      </tbody>
                    </table>

                  </div>

                </div>
                <div style="display: flex;justify-content: right">
                  <paginations :limit.sync="params.size" :page.sync="params.current" :total="total"
                               @pagination="getPage"></paginations>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>
<script>
import {mixins} from "@/views/Finance/components/income/mixins";
import Paginations from "@/components/Paginations/index.vue";

export default {
  components: {Paginations},
  mixins: [mixins],
}
</script>
