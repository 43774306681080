import service from "@/utils/request"


export function getPlaceSuggestionApi(params) {
    return service({
        url: '/config/qq/map/ws/place/v1/suggestion/',
        method: 'get',
        params
    })
}
