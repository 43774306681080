<template>
  <div>
    <form action="#">
      <!--begin::Card-->
      <div class="card mb-7" style="border-top-left-radius: 0px;border-top-right-radius: 0px">
        <!--begin::Card body-->
        <div class="card-body">
          <!--begin::Compact form-->
          <div class="d-flex align-items-center">
            <!--begin::Input group-->
            <div style="display: flex;flex-direction: row">
              <div class="position-relative w-md-400px me-md-2">
                            <span
                                class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     width="24px" height="24px" viewBox="0 0 24 24">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24"></rect>
                                        <path
                                            d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                            fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                        <path
                                            d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                            fill="#000000" fill-rule="nonzero"></path>
                                    </g>
                                </svg>
                            </span>
                <!--end::Svg Icon-->
                <input type="text" class="form-control form-control-solid ps-10" id="sTitle" name="shopName"
                       value="" placeholder="订单编号" v-model="queryParams.orderNo">
              </div>
              <div class="d-flex align-items-center">
                <div class="btn btn-primary me-5" @click="resetGetAfsList">查询</div>
                <a id="kt_horizontal_search_advanced_link" class="btn btn-link" data-bs-toggle="collapse"
                   href="#kt_advanced_afs_search_form">高级查询</a>
              </div>
            </div>

            <!--end::Input group-->
            <!--begin:Action-->

            <!--end:Action-->
            <div style="margin-right: 10px">
              <!--begin::Actions-->

              <!--end::Actions-->
            </div>
          </div>
          <!--end::Compact form-->
          <!--begin::高级搜索-->
          <div class="collapse" id="kt_advanced_afs_search_form">
            <!--begin::Separator-->
            <div class="separator separator-dashed mt-9 mb-6"></div>
            <!--end::Separator-->
            <!--begin::Row-->
            <div class="row g-8 mb-8">
              <div class="col-lg-3">
                <label class="fs-6 form-label fw-bolder text-dark">服务状态</label>
                <!--begin::Select-->
                <br>
                <el-select v-model="queryParams.status" placeholder="请选择">
                  <el-option value="" label="全部">全部</el-option>
                  <el-option value="10" label="申请中待审核">申请中待审核</el-option>
                  <el-option value="20" label="审核完成待收货">审核完成待收货</el-option>
                  <el-option value="30" label="审核完成待收货">收货完成待处理</el-option>
                  <el-option value="40" label="处理完成（如需退款则等待退款）">处理完成（如需退款则等待退款）</el-option>
                  <el-option value="50" label="待用户确认">待用户确认</el-option>
                  <el-option value="60" label="用户确认完成">用户确认完成</el-option>
                  <el-option value="70" label="取消">取消</el-option>
                  <el-option value="80" label="驳回">驳回</el-option>
                </el-select>
                <!-- <select  v-model="queryParams.shopStatus" class="form-select form-select-solid"
                    data-control="select2" data-placeholder="请选择" data-hide-search="true" id="shopStatus"
                    name="shopStatus">
                    <option value="">请选择</option>
                    <option value="1">使用中</option>
                    <option value="2">已过期</option>
                    <option value="3">已停用</option>

                </select> -->
              </div>

              <!--begin::Col-->
              <div class="col-lg-4">
                <label class="fs-6 form-label fw-bolder text-dark">创建日期区间</label>
                <!-- <input class="form-control form-control-solid" placeholder="选择日期区间" id="shopCreateDate" /> -->
                <br>
                <el-date-picker v-model="createDateRange" type="daterange" align="right" unlink-panels
                                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                                value-format="yyyy-MM-dd" :picker-options="pickerOptions" @change="startDateChange">
                </el-date-picker>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
          </div>
          <!--end::Advance form-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </form>

    <div class="tab-content">
      <!--begin::Tab pane-->
      <div id="kt_project_users_card_pane2" class="tab-pane fade active show">
        <!--begin::Card-->
        <div class="card card-flush">
          <!--begin::Card body-->
          <div class="card-body pt-0">
            <!--begin::Table container-->
            <div class="table-responsive tableProduct">
              <!--begin::Table-->
              <el-table :data="rows" empty-text="未查询到数据">
                <template #empty>
                  <p >
                    暂无更多
                  </p>
                </template>
<!--                <el-table-column-->
<!--                    type="selection" width="50">-->
<!--                </el-table-column>-->
                <el-table-column
                    label="商品名称"
                    width="300"
                >
                  <template slot-scope="scope">
                    <div class="d-flex align-items-center">
                      <div class="symbol symbol-50px me-5"> <img :src="scope.row.picUrl"
                                                                 class="" alt=""> </div>
                      <div class="d-flex justify-content-start flex-column cursor-pointer">
                        <a
                           class="text-muted  d-block fs-7  text-hover-primary ">{{ scope.row.skuName }}
                        </a>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                    label="订单编号"
                    width="160"
                >
                  <template slot-scope="scope">
                    <a  class="text-dark fw-bolder  d-block mb-1 fs-7">{{ scope.row.orderNo }}</a>
                  </template>
                </el-table-column>

                <el-table-column
                    label="数量"
                    width="80"
                >
                  <template slot-scope="scope">
                    <a  class="text-dark fw-bolder  d-block mb-1 fs-7">{{ scope.row.num }}</a>
                  </template>
                </el-table-column>

                <el-table-column
                    label="类型"
                    width="80"
                >
                  <template slot-scope="scope">
                    <a  class="text-dark fw-bolder  d-block mb-1 fs-7">{{ scope.row.typeDesc }}</a>
                  </template>
                </el-table-column>

                <el-table-column
                    label="服务状态"
                    width="120"
                >
                  <template slot-scope="scope">
                    <a  class="text-dark fw-bolder  d-block mb-1 fs-7">{{ scope.row.statusDesc}}</a>
                    <a class=" fs-7" v-if="scope.row.submitApplyStatus==2">未提交到京东</a>
                  </template>
                </el-table-column>
                <el-table-column
                    label="下单时间"
                >
                  <template slot-scope="scope">
                    <a  class="text-dark fw-bolder  d-block mb-1 fs-7">{{ scope.row.orderTime}}</a>
                  </template>
                </el-table-column>

                <el-table-column
                    label="申请时间"
                >
                  <template slot-scope="scope">
                    <a  class="text-dark fw-bolder  d-block mb-1 fs-7">{{ scope.row.createTime}}</a>
                  </template>
                </el-table-column>

                <el-table-column
                    label="操作"
                >
                  <template slot-scope="scope">
                    <a  class="btn btn-success   btn-sm px-4" data-bs-toggle="modal"
                        data-bs-target="#editProduct2ShopModal"
                        @click="openThisAfsDetail(scope.row)">查看并处理</a>&nbsp;&nbsp;

                  </template>
                </el-table-column>
              </el-table>

              <!--end::Table-->
            </div>
            <!--end::Table container-->
            <!-- 分页按钮 -->
            <el-pagination style="display:flex;justify-content:flex-end;margin-top: 5px" @size-change="handleSizeChange"
                           @current-change="handleCurrentChange" :current-page="queryParams.current" :page-sizes="[10,20,30,50,100]"
                           :page-size="queryParams.size" layout="total, sizes, prev, pager, next, jumper" :total="totalCount"
                           background>
            </el-pagination>
          </div>
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
    <AfterSaleProcess ref="afterSaleProcess" @getAfsList="getAfsList"></AfterSaleProcess>
  </div>
</template>
<script>

import {getAfsListApi} from "@/api/lxh/applet/afterSale";
import uploadImg from "@/views/Shop/component/uploadImg.vue";
import AfterSaleProcess from "@/views/Order/components/afterSaleProcess.vue";

  export default {
    components: {uploadImg,AfterSaleProcess},
    data(){
      return{
        aa:"",
        createDateRange:"",
        //日期选择器 用
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        queryParams:{
          orderNo:"",
          createDateStart: null,
          createDateEnd: null,
          status:"",
          current:1,
          size:10
        },
        rows:[],
        totalCount:0,
        afsDetailVisible:false,
      }
    },
    computed:{
    },
    methods:{
      init(){
          this.resetGetAfsList();
      },
      startDateChange(param) {
        if (Array.isArray(param)) {
          console.log("afa")
          this.queryParams.createDateStart = param[0]
          this.queryParams.createDateEnd = param[1]
        } else {
          this.queryParams.createDateStart = null
          this.queryParams.createDateEnd = null
        }
      },
      async getAfsList() {
        if(this.queryParams.status=="0"){
          this.queryParams.status = null
        }
        let { data } = await getAfsListApi(this.queryParams)
        this.rows = data.rows
        this.totalCount = data.total
      },
      //重置分页参数，获取list
      resetGetAfsList() {
        this.queryParams.current = 1;
        this.getAfsList();
      },
      handleCurrentChange(param) {
        this.queryParams.current = param
        this.getAfsList();
      },
      handleSizeChange(param) {
        this.queryParams.current = 1
        this.queryParams.size = param
        this.getAfsList();
      },
      openThisAfsDetail(item){
        this.afsDetailVisible = true
        this.$refs.afterSaleProcess.init(item.id)
      },

    },

    created() {
      // this.getAfsList();
      this.getProvinceRegionList()
    }
  }
</script>