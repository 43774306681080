import service from "@/utils/request"
/**
 * 获取素材库
 */
export function queryMaterialPageApi(params) {
    return service({
        url: "/material/queryMaterialPage",
        method: "get",
        params
    })
}

/**
 * 上传图片
 */
export function uploadMaterialApi(data) {
    return service({
        url: "/material/saveMaterial",
        method: "post",
        data
    })
}
