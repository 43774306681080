<template>
  <div :class="{'hidden':hidden}" class="pagination-container">
    <el-pagination
      :background="background"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      :layout="layout"
      :page-sizes="pageSizes"
      :total="total"
      v-bind="$attrs"
      id="sj_pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import { scrollTo } from '@/utils/scroll-to'

export default {
  name: 'PaginationEl',
  props: {
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 20
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 50]
      }
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    }
  },
  methods: {
    handleSizeChange(val) {
      this.$emit('pagination', { page: this.currentPage, limit: val })
      if (this.autoScroll) {
        scrollTo(0, 800)
      }
    },
    handleCurrentChange(val) {
      this.$emit('pagination', { page: val, limit: this.pageSize })
      if (this.autoScroll) {
        scrollTo(0, 800)
      }
    }
  }
}
</script>
<style>
#sj_pagination >ul>li{
    border-radius: .475rem;
}
#sj_pagination .el-pagination__sizes{
  background: #F9F9F9;
}
#sj_pagination .el-pagination__sizes .el-input--suffix input{
  background: #F9F9F9;
  border: none;
}
#sj_pagination .el-pagination__jump .el-pagination__editor input{
  background: #F9F9F9;
  border: none;
}
#sj_pagination >ul>li.active{
  background: #009ef7;border-radius: .475rem;
}
#sj_pagination >button.btn-prev{
  border-radius: .475rem;
}
#sj_pagination >button.btn-next{
  border-radius: .475rem;
}
</style>

<style scoped>
.pagination-container {
  background: #fff;
  padding:8px 16px;
}
.pagination-container.hidden {
  display: none;
}
</style>
