<template>
  <el-dialog
      title="添加发票抬头"
      :visible.sync="createDialog"
      top="30px"
      width="50%"
      id="createDialog"
      :before-close="handleClose">
    <div>
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="发票抬头" prop="invoiceHeader">
          <el-input
              v-model="form.invoiceHeader"
              placeholder="发票抬头"
          ></el-input>
        </el-form-item>
        <el-form-item label="发票税号" prop="invoiceTaxNumber">
          <el-input
              v-model="form.invoiceTaxNumber"
              placeholder="发票税号"
          ></el-input>
        </el-form-item>
        <el-form-item label="注册地址" prop="Address">
          <el-input v-model="form.Address" placeholder="注册地址"></el-input>
        </el-form-item>
        <el-form-item label="注册电话" prop="PhoneNumber">
          <el-input v-model="form.PhoneNumber" placeholder="注册电话"></el-input>
        </el-form-item>
        <el-form-item label="开户银行" prop="openingBank">
          <el-input v-model="form.openingBank" placeholder="开户银行"></el-input>
        </el-form-item>
        <el-form-item label="开户账号" prop="accountOpening">
          <el-input
              v-model="form.accountOpening"
              placeholder="开户账号"
          ></el-input>
        </el-form-item>
        <el-form-item label="收票人邮箱" prop="recipientEmail">
          <el-input
              v-model="form.recipientEmail"
              placeholder="收票人邮箱"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否专票" prop="special">
          <el-radio v-model="form.special" :label="true">是</el-radio>
          <el-radio v-model="form.special" :label="false">否</el-radio>
        </el-form-item>
        <el-form-item>
          <el-button @click="createDialog = false">取消</el-button>
          <el-button type="primary" @click="onSubmit">添加</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import {addStaffFpApi} from "@/api/invoiceApi";
import {Message} from "element-ui";

export default {
  data() {
    return {
      id: "",
      createDialog: false,
      form: {
        // 发票抬头
        invoiceHeader: "",
        invoiceTaxNumber: "",
        Address: "",
        PhoneNumber: "",
        openingBank: "",
        accountOpening: "",
        recipientEmail: "",
        special: false,
      },
      rules: {
        invoiceHeader: [
          {required: true, message: "请输入发票抬头", trigger: "blur"},
        ],
        invoiceTaxNumber: [
          {required: true, message: "请输入发票税号", trigger: "blur"},
        ],
        Address: [
          {required: true, message: "请输入注册地址", trigger: "blur"},
        ],
        PhoneNumber: [
          {required: true, message: "请输入注册电话", trigger: "blur"},
        ],
        openingBank: [
          {required: true, message: "请输入开户银行", trigger: "blur"},
        ],
        accountOpening: [
          {required: true, message: "请输入开户账号", trigger: "blur"},
        ],
        recipientEmail: [
          {required: true, message: "请输入收票人邮箱", trigger: "blur"},
        ],
        special: [
          {required: true, message: '请至少选择一个发票性质', trigger: 'change'}
        ],
      },
    };
  },
  methods: {
    handleClose(done) {
      done()
    },

    init() {
      this.form = {
        // 发票抬头
        invoiceHeader: "",
        invoiceTaxNumber: "",
        Address: "",
        PhoneNumber: "",
        openingBank: "",
        accountOpening: "",
        recipientEmail: "",
        special: false,
      }
      this.createDialog = true;
    },
    onSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          //  验证完后
          await addStaffFpApi({
            fpName: this.form.invoiceHeader,
            fpNo: this.form.invoiceTaxNumber,
            fpAdd: this.form.Address,
            fpTel: this.form.PhoneNumber,
            fpYh: this.form.openingBank,
            fpYhzh: this.form.accountOpening,
            sjEmail: this.form.recipientEmail,
            special: this.form.special,
          });
          Message.success("添加成功");
          this.createDialog = false;
          this.$emit("getInvoiceList");
        }
      });
    },
  },
};
</script>
<style scoped>
</style>
