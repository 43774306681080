<template>
  <div>
    <div ref="chartContainer" style="width: 100%;height: 300px;padding-top: 20px"></div>
  </div>
</template>

<script>
import echarts from "echarts";
import {getMonthDay} from "@/utils/dateUtils";
import {getMonthOrderCountApi, getThirtyDaySalesRevenueApi} from "@/api/orderApi";

export default {
  name: "MonthPrice",
  data() {
    return {
      dayArray:[],
      chartOptions:  {
        title: {
          left: 'center',
          text: '近30天消费金额',
        },
        xAxis: {
          type: 'category',
          data: getMonthDay().reverse()
        },
        yAxis: {
          type: 'value'
        },
        tooltip: {
          trigger: 'axis'
        },
        series: [
          {
            data: [],
            type: 'line',
            smooth: true
          }
        ],
      }
    }
  },
  methods: {
    async getThirtyDaySalesRevenue(){
      this.dataArr=[];
      /*let {data:res}=await getThirtyDaySalesRevenueApi();
      let arr=[]
      res.forEach(e=>{
        arr.push(e.revenue);
      })*/
      // let {data:res}=await getMonthOrderCountApi();
      let res=[ {
        "day": "2024-06-24",
        "count": 10
      },
        {
          "day": "2024-06-25",
          "count": 20
        },
        {
          "day": "2024-06-26",
          "count": 10
        },
        {
          "day": "2024-06-27",
          "count": 2
        },
        {
          "day": "2024-06-28",
          "count": 20
        },
        {
          "day": "2024-06-29",
          "count": 70
        },
        {
          "day": "2024-06-30",
          "count": 50
        },
        {
          "day": "2024-07-01",
          "count": 3144
        },
        {
          "day": "2024-07-02",
          "count": 2104
        },
        {
          "day": "2024-07-03",
          "count": 1248
        },
        {
          "day": "2024-07-04",
          "count": 1447
        },
        {
          "day": "2024-07-05",
          "count": 1253
        },
        {
          "day": "2024-07-06",
          "count": 1284
        },
        {
          "day": "2024-07-07",
          "count": 1347
        },
        {
          "day": "2024-07-08",
          "count": 1236
        },
        {
          "day": "2024-07-09",
          "count": 1855
        },
        {
          "day": "2024-07-10",
          "count": 1379
        },
        {
          "day": "2024-07-11",
          "count": 1654
        },
        {
          "day": "2024-07-12",
          "count": 1789
        },
        {
          "day": "2024-07-13",
          "count": 1247
        },
        {
          "day": "2024-07-14",
          "count": 1356
        },
        {
          "day": "2024-07-15",
          "count": 1624
        },
        {
          "day": "2024-07-16",
          "count": 1234
        },
        {
          "day": "2024-07-17",
          "count": 1111
        },
        {
          "day": "2024-07-18",
          "count": 1200
        },
        {
          "day": "2024-07-19",
          "count": 1400
        },
        {
          "day": "2024-07-20",
          "count": 2800
        },
        {
          "day": "2024-07-21",
          "count": 2000
        },
        {
          "day": "2024-07-22",
          "count": 2400
        },
        {
          "day": "2024-07-23",
          "count": 1446
        }]
      let arr=[]
      res.forEach(e=>{
        arr.push(e.count);
      })
      this.dataArr=arr
    },
    async initChart() {
      // 获取图表容器
      let chartContainer = this.$refs.chartContainer;
      // 初始化图表
      let myChart = echarts.init(chartContainer);
      await this.getThirtyDaySalesRevenue();
      this.chartOptions.series[0].data=this.dataArr;
      // 使用 setOption 方法设置图表配置和数据
      myChart.setOption(this.chartOptions);
    },
  },
  mounted() {

    this.$nextTick(() => {
      this.initChart();
    })
  }
}
</script>


<style scoped>

</style>