import service from "@/utils/request"

export function getFansListApi(params){
    return service({
        url: "/saas/user/follower/page",
        method: "get",
        params
    })
}

export function getFansCountApi(params){
    return service({
        url: "/saas/user/follower/count",
        method: "get",
        params
    })
}