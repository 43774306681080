<template>
  <div style="margin-bottom: 30px">
    <div class="card h-md-100">
      <div class="card-header align-items-center border-0">
        <h3 class="fw-bold text-gray-900 m-0">实时数据</h3>
      </div>
      <div class="card-body pt-2" style="display: flex;justify-content: space-between">
        <div style="width: 18%;text-align: center;background: #fff;box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.03);padding-top: 16px;padding-bottom: 10px" class="card">
          <div style="font-size: 14px;font-weight: 500">消费金额</div>
          <div style="margin-top: 6px;font-size: 18px;font-weight: 400">&yen;0.00</div>
        </div>
        <div style="width: 18%;text-align: center;background: #fff;box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.03);padding-top: 16px;padding-bottom: 10px" class="card">
          <div style="font-size: 14px;font-weight: 500">新增粉丝</div>
          <div style="margin-top: 6px;font-size: 18px;font-weight: 400">0</div>

        </div>
        <div style="width: 18%;text-align: center;background: #fff;box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.03);padding-top: 16px;padding-bottom: 10px" class="card">
          <div style="font-size: 14px;font-weight: 500">消费笔数</div>
          <div style="margin-top: 6px;font-size: 18px;font-weight: 400">&yen;0</div>

        </div>
        <div style="width: 18%;text-align: center;background: #fff;box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.03);padding-top: 16px;padding-bottom: 10px" class="card">
          <div style="font-size: 14px;font-weight: 500">笔单价</div>
          <div style="margin-top: 6px;font-size: 18px;font-weight: 400">&yen;0.00</div>

        </div>
        <div style="width: 18%;text-align: center;background: #fff;box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.03);padding-top: 16px;padding-bottom: 10px" class="card">
          <div style="font-size: 14px;font-weight: 500">新增评论数</div>
          <div style="margin-top: 6px;font-size: 18px;font-weight: 400">&yen;0</div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "info",
  data() {
    return {}
  },
  filters: {},
  methods: {},
  created() {
  },
}
</script>

<style scoped>

</style>
