<template>
  <el-dialog
      title="编辑营业时间"
      :visible.sync="dialogVisible"
      width="30%"
      top="10px"
      :before-close="handleClose">
    <div class="row mb-12">
      <label class="col-lg-2 col-form-label fw-bold fs-6">
        <span>营业时间</span>
      </label>
      <div class="col-lg-10 fv-row">
        <div class="d-flex align-items-center mt-3" style="flex-wrap: wrap">
          <div style="margin-right: 10px;margin-bottom: 10px;display: flex;align-items: center;width: 90%"
               v-for="(item,index) in openingHours">
            <div style="display:flex;align-items: center;justify-content: space-between;width: 100%">
              <el-time-select
                  style="width: 47%"
                  v-model="openingHours[index][0]"
                  :picker-options="{
                    start: '00:00',
                    step: '00:30',
                    end: '24:00'
                  }"
                  placeholder="选择时间">
              </el-time-select>
              <div>~</div>
              <el-time-select
                  style="width: 47%"
                  v-model="openingHours[index][1]"
                  :picker-options="{
                    start: '00:00',
                    step: '00:30',
                    end: '24:00'
                  }"
                  placeholder="选择时间">
              </el-time-select>
            </div>

            <div style="width: 120px;margin-left: 10px">
              <el-link icon="el-icon-close" @click="deleteOpeningHours(index)" :underline="false">删除</el-link>
            </div>
          </div>
        </div>
        <div>
          <el-link icon="el-icon-plus" @click="addOpeningHours" :underline="false" type="danger">添加</el-link>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <sj-button type="secondary" @click="dialogVisible = false">取消</sj-button>
      <sj-button type="success" @click="saveStoreTime" style="margin-left: 20px">保存</sj-button>
    </span>
  </el-dialog>
</template>
<script>
import {updateStoreOpeningHoursApi} from "@/api/lxh/store";

export default {
  title: 'editTime',
  data() {
    return {
      dialogVisible: false,
      openingHours: [],
      storeInfo: {
        storeId: "",
        openingHours: [],
      }
    }
  },
  created() {
  },
  methods: {
    async saveStoreTime() {
      let id=this.storeInfo.id;
      let arr=[]
      this.openingHours.forEach(item => {
        let obj={
          startTime: item[0],
          endTime: item[1]
        }
        arr.push(obj)
      })
      await updateStoreOpeningHoursApi({
        id,openingHours:arr
      })
      this.dialogVisible = false
      this.$emit('getList')
      this.$message({
        message: '保存成功！',
        type: 'success'
      });
    },
    init(item) {
      this.storeInfo = JSON.parse(JSON.stringify(item))
      this.dialogVisible = true
      this.openingHours=[]
      this.storeInfo.openingHours.forEach(item => {
        let arr=JSON.parse(item)
        this.openingHours.push([arr[0],arr[1]])
      })
    },
    deleteOpeningHours(index) {
      this.openingHours.splice(index, 1);
    },
    addOpeningHours() {
      this.openingHours.push(['00:00', '00:00'])
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {
          });
    }
  }
}
</script>
