import service from "@/utils/request"

export function addSaasAddressApi(data) {
    return service({
        url: "/saas/address/add",
        method: "post",
        data
    })
}

export function updateSaasAddressApi(data) {
    return service({
        url: "/saas/address/update",
        method: "post",
        data
    })
}

export function getDefaultAddressDetailApi(data) {
    return service({
        url: "/saas/address/getDefaultAddressDetail",
        method: "get",
        params: data
    })
}